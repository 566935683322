import React, { useEffect, useState } from "react";
import Link from "@material-ui/core/Link";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  TextField,
  MenuItem,
  FormControl,
  Select,
  Button,
  Typography,
  Box,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuList,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
} from "@mui/material";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ImageIcon from "@mui/icons-material/Image";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddServices from "./addServices";
import { fetchCustomers } from "../store/customer";
import { fetchSpecialists } from "../store/specialist";
import { addAppointment } from "../store/appointment";
import "./appointment.css";
import clipboardIcon from "../assets/images/clipboard.png";
import eyeoffIcon from "../assets/images/eyeoff.png";
import slashIcon from "../assets/images/slash.png";
import dollorIcon from "../assets/images/dollar.png";
import { TableFooter } from "@material-ui/core";
import clocktimIcon from "../assets/images/appointment/clock.png";
import prigetagIcon from "../assets/images/appointment/pricetag.png";
import timerIcon from "../assets/images/appointment/timer.png";
import ClearIcon from "@mui/icons-material/Clear";
import filetextIcon from "../assets/images/file-text.png";
import AddCustomer from "./addCustomer";
import { TimePicker } from "@mui/x-date-pickers";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const AppointmentForm = (props) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    customer: "",
    services: "",
    date: null,
    startTime: null,
    bookedBy: "",
    bookingChannel: "",
    repeat: "off",
    repeatOn: null,
    ends: "Never",
    endDate: "",
    appointmentNote: "",
    attachFile: null,
  });
  const [searchVal, setSearchVal] = useState("");
  const [isOpenTimeStart, setIsOpenTimeStart] = useState(false);
  const [customerListState, setCustomerListState] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [totalSummary, setTotalSummary] = useState({
    price: 0,
    duration: 0,
  });
  const [openServiceModel, setOpenServiceModel] = useState(false);
  const { data: customersList } = useSelector((state) => state.customers.all);
  const { data: specialistData } = useSelector(
    (state) => state.specialists.all
  );
  const {
    data: addAppointmentData = null,
    loading = false,
    error = null,
  } = useSelector((state) => state.appointment.add || {});
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  useEffect(() => {
    dispatch(fetchCustomers({ searchVal }));
  }, [dispatch, searchVal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    const newFiles = files.map((file) => ({
      file,
      preview: file.type.startsWith("image/")
        ? URL.createObjectURL(file)
        : null, // Generate preview URL for images only
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleFileClick = (file) => {
    if (file.type.startsWith("image/")) {
      window.open(URL.createObjectURL(file), "_blank");
    } else if (file.type === "application/pdf") {
      window.open(URL.createObjectURL(file), "_blank");
    }
  };

  const handleDaySelect = (day) => {
    setFormData({ ...formData, repeatOn: day });
  };

  const handleEndsChange = (event) => {
    setFormData({
      ...formData,
      ends: event.target.value,
      endDate: event.target.value === "On" ? formData.endDate : "",
    });
  };

  const handleDateChangeEnds = (value) => {
    setFormData({ ...formData, endDate: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formPayload = new FormData();

    let checkValidation = true;
    if (!selectedCustomer?._id) {
      toast.error("Please select customer", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }

    for (let dt of selectedServices) {
      if (!dt?._id) {
        toast.error("Please select services", {
          toastId: "form-validation",
        });
        checkValidation = false;
        return 0;
      }
      if (!dt?.startTime) {
        toast.error("Please select start time", {
          toastId: "form-validation",
        });
        checkValidation = false;
        return 0;
      }
      if (!dt?.preferred_specialist) {
        toast.error("Please select preferred specialist", {
          toastId: "form-validation",
        });
        checkValidation = false;
        return 0;
      }
    }

    if (!formData?.bookedBy) {
      toast.error("Please select booked by", {
        toastId: "form-validation",
      });
      checkValidation = false;
      return 0;
    }
    if (!formData?.bookingChannel) {
      toast.error("Please select booking channel", {
        toastId: "form-validation",
      });
      checkValidation = false;
      return 0;
    }

    if (formData?.repeat != "off" && !formData?.repeatOn) {
      toast.error("Please select repeat on day", {
        toastId: "form-validation",
      });
      checkValidation = false;
      return 0;
    } else if (
      formData?.repeat != "off" &&
      formData?.ends == "On" &&
      !formData?.endDate
    ) {
      toast.error("Please select end date", {
        toastId: "form-validation",
      });
      checkValidation = false;
      return 0;
    }

    if (!formData?.date) {
      toast.error("Please select date of appointment", {
        toastId: "form-validation",
      });
      checkValidation = false;
      return 0;
    }

    if (checkValidation) {
      formPayload.append("customerId", selectedCustomer?._id);

      for (let i in selectedServices) {
        formPayload.append(
          `services[${i}][subcategoryId]`,
          selectedServices[i]?._id
        );
        formPayload.append(
          `services[${i}][startTime]`,
          selectedServices[i]?.startTime
        );
        formPayload.append(
          `services[${i}][specialistId]`,
          selectedServices[i]?.preferred_specialist
        );
      }
      formPayload.append("bookedBy", formData?.bookedBy);
      formPayload.append("bookingChannel", formData?.bookingChannel);
      formPayload.append("repeat", formData?.repeat);
      if (formData?.repeat !== "off") {
        if (formData?.repeatOn) {
          formPayload.append("repeatOn", formData?.repeatOn);
        }
        if (formData?.ends == "On") {
          formPayload.append("ends", "On Date");
          if (formData?.endDate) {
            formPayload.append(
              "endDate",
              moment(formData?.endDate).format("YYYY-MM-DD")
            );
          }
        }
      }
      formPayload.append("appointmentNotes", formData?.appointmentNote);
      formPayload.append("status", "notconfirmed");
      formPayload.append(
        "appointmentDate",
        moment(formData?.date).format("YYYY-MM-DD")
      );

      uploadedFiles.forEach((fileObj, index) => {
        formPayload.append("attachments", fileObj.file); // Use the same key for all files
      });

      const payloadObject = {};
      for (let pair of formPayload.entries()) {
        payloadObject[pair[0]] = pair[1];
      }
      try {
        const resultAction = await dispatch(
          addAppointment(formPayload)
        ).unwrap();
        if (resultAction.success) {
          dispatch(fetchSpecialists());
          toast.success(resultAction.message, { toastId: "api-success" });
          props.setAddAppointmentToggle(false);
        } else {
          toast.error(resultAction.message, { toastId: "api-success" });
        }
      } catch (error) {
        toast.error(`Failed to create appointment: ${error}`, {
          toastId: "api-error",
        });
        console.error("API Error:", error);
      }
    }
  };

  const onClickServiceHandler = () => {
    setOpenServiceModel(true);
  };
  const addServiceCloseHandler = (data) => {
    setOpenServiceModel(data);
  };

  function handleCustomerSelect(obj) {
    setSearchVal(
      obj?.first_name + " " + obj?.last_name + " - " + obj?.phone_number_mobile
    );
    setSelectedCustomer(obj);
    setCustomerListState(false);
  }

  function handleCustomerClose() {
    setSearchVal("");
    setSelectedCustomer({});
    setCustomerListState(false);
  }

  const handleSeriveDelete = (rowData) => {
    let tempData = selectedServices.filter((dt) => dt._id != rowData._id);
    setSelectedServices([...tempData]);
    setTotalSummary((prev) => {
      return {
        price: prev.price - rowData.price,
        duration: prev.duration - rowData.duration,
      };
    });
  };

  const handleSpecialistChange = (event, index) => {
    let tempObj = JSON.parse(JSON.stringify(selectedServices));
    tempObj[index]["preferred_specialist"] = event.target.value;
    setSelectedServices([...tempObj]);
  };

  const handleStartTimeChange = (value, index) => {
    let tempObj = JSON.parse(JSON.stringify(selectedServices));
    const formattedTime = value instanceof Date
      ? value.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      : value;
  
    tempObj[index]["startTime"] = formattedTime;
    setSelectedServices([...tempObj]);
  };
  
  // Utility function to shorten file name
  function shortenFileName(fileName) {
    const maxLength = 10; // Maximum length for the file name
    const dotIndex = fileName.lastIndexOf(".");
    const extension = dotIndex !== -1 ? fileName.slice(dotIndex) : "";
    const baseName = dotIndex !== -1 ? fileName.slice(0, dotIndex) : fileName;

    if (baseName.length > maxLength) {
      return `${baseName.slice(0, maxLength)}...${extension}`;
    }
    return fileName;
  }

  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [addCustomerToggle, setAddCustomerToggle] = useState(false);
  const openCreatCustomerModal = () => {
    setAddCustomerToggle(true);
    setOpenCustomerModal(true);
    //props.setAddAppointmentToggle(false)
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} className="kk">
      <Typography className="Heading_pop Heading_popOuter_withCloseIocn">
        Book Appointment
      </Typography>
      <span
        className="crosssicon"
        onClick={() => props.setAddAppointmentToggle(false)}
      >
        <ClearIcon />
      </span>
      <Box
        className="AppointPop"
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          // maxWidth: "500px",
          margin: "0 auto",
          padding: "20px",
          boxShadow: 3,
          borderRadius: "12px",
          bgcolor: "#ffffff",
        }}
      >
        <h4>Customer</h4>
        <TextField
          className="inputfield"
          name="customer"
          value={searchVal}
          fullWidth
          margin="normal"
          autoComplete="off"
          placeholder="Name, Email or Phone Number"
          onChange={(e) => {
            setSearchVal(e.target.value);
            setCustomerListState(true);
          }}
          InputProps={{
            startAdornment: (
              <IconButton sx={{ padding: "10px" }}>
                <SearchIcon />
              </IconButton>
            ),
            endAdornment: (
              <IconButton sx={{ padding: "10px" }}>
                <CloseIcon onClick={handleCustomerClose} />
              </IconButton>
            ),
          }}
        />
        {searchVal && customerListState ? (
          <div className="serchdiv">
            <MenuList>
              {customersList?.length
                ? customersList?.map((dt) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          handleCustomerSelect(dt);
                        }}
                        key={dt?._id}
                        value={dt?._id}
                      >
                        {dt?.first_name} {dt?.last_name}
                      </MenuItem>
                    );
                  })
                : ""}

              <MenuItem>
                <Link onClick={openCreatCustomerModal}>
                  <span className="creatplus">+</span> Create New
                </Link>
              </MenuItem>
            </MenuList>
          </div>
        ) : (
          ""
        )}
        {Object.keys(selectedCustomer).length ? (
          <div className="customer-card custcustomer-card">
            <div className="card-header">
              <div className="card-header-left ">
                <h2>{`${selectedCustomer.first_name} ${selectedCustomer.last_name}`}</h2>
                <span className="newbg">New</span>
              </div>
              <div className="card-header-left card-header-rit">
                <span className="loyalty-tier loyalty-inner">
                  Loyalty Points:{" "}
                  <span className="low">
                    {selectedCustomer.loyaltyPoints || 343}
                  </span>
                </span>
                <span className="loyalty-tier">
                  Loyalty Tier:
                  <span className="low">
                    {selectedCustomer.loyaltyTier || "Low"}
                  </span>
                </span>
              </div>
            </div>
            <p className="phone-email">
              <span>{selectedCustomer.phone_number_mobile}</span> ·{" "}
              <span>{selectedCustomer.email}</span>
            </p>
            <div className="card-actions">
              <button className="btn smallspan">Notes (2)</button>
              <button className="btn smallspan">History (0)</button>
              <button className="btn smallspan">Check Out</button>
              <button className="btn smallspan">Edit</button>
            </div>
            <div className="card-actions bottom-card-actions">
              <div className="bottom-card-actions-lft">
                <p>
                  <img src={clipboardIcon} /> Total Visits
                  <span>{selectedCustomer.totalVisits || 0}</span>
                </p>
                <p>
                  <img src={eyeoffIcon} />
                  No Shows<span>{selectedCustomer.noShows || 0}</span>
                </p>
                <p>
                  <img src={slashIcon} />
                  Cancellations{" "}
                  <span>{selectedCustomer.cancellations || 0}</span>
                </p>
              </div>
              <div className="bottom-card-actions-rit">
                <p>
                  <img src={dollorIcon} />
                  Deposit<span>${selectedCustomer.deposit}</span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <hr></hr>

        <h4 style={{ marginBottom: "8px" }}>Services</h4>

        {selectedServices.length ? (
          <Box className="serviceTable">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SERVICE</TableCell>
                    <TableCell>PREFERRED SPECIALIST</TableCell>
                    <TableCell>PRICE (with Tax)</TableCell>
                    <TableCell>START TIME</TableCell>
                    <TableCell>DURATION</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedServices.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <span
                          className="dotclr"
                          style={{ backgroundColor: row?.appointment_colour }}
                        ></span>
                        {row.service_name}
                      </TableCell>
                      <TableCell>
                        <div
                          className="selectdropdown"
                          style={{ textAlign: "center" }}
                        >
                          <select
                            value={row?.preferred_specialist || ""}
                            onChange={(e) => handleSpecialistChange(e, index)}
                          >
                            <option value="">Any</option>
                            {specialistData.map((dt) => {
                              return (
                                <option key={dt._id} value={dt._id}>
                                  {dt?.first_name} {dt?.last_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="selectdropdown">
                          <span>
                            <img src={prigetagIcon} />
                          </span>
                          ${row.price}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="selectdropdown timeselectdropdwn p-0">
                          <TimePicker
                            className="rounded-pill"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                width: "100%", // Ensures full width
                              },
                              "& .MuiInputAdornment-positionEnd": {
                                display: "none",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "1px  !important",
                                borderRadius: "20px",
                                borderColor: "#E1E3E6",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#E1E3E6 !important",
                              },
                              "& .MuiInputBase-input": {
                                padding: "9px 16px !important",
                                fontSize: "14px !important",
                                fontFamily: "'Satoshi-Regular !important",
                              },
                            }}
                            open={isOpenTimeStart}
                            onOpen={() => setIsOpenTimeStart(true)}
                            onClose={() => setIsOpenTimeStart(false)}
                            onChange={(value) => handleStartTimeChange(value, index)} 
                            views={["hours", "minutes"]}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                InputProps: {
                                  startAdornment: (
                                    <InputAdornment
                                      placeholder="Select Time"
                                      position="start"
                                      onClick={() => setIsOpenTimeStart(true)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <AccessTimeIcon />
                                    </InputAdornment>
                                  ),
                                },
                              },
                            }}
                          />
                        </div>
                      </TableCell>

                      <TableCell>
                        <div
                          className="selectdropdown"
                          style={{ textAlign: "center" }}
                        >
                          <span>
                            <img src={timerIcon} />
                          </span>
                          {(row.duration / 60).toFixed(2)}
                        </div>
                      </TableCell>
                      {/* <TableCell>
                        <IconButton onClick={() => handleSeriveDelete(row)} classname="redcross">
                          <CloseIcon />
                        </IconButton>
                      </TableCell> */}
                      <TableCell>
                        <IconButton onClick={() => handleSeriveDelete(row)}>
                          <CloseIcon
                            sx={{
                              color: "#F05252!important",
                              fontSize: "22px",
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter className="tablefooter">
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <span>
                        <img src={prigetagIcon} />${totalSummary.price}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="tiltime">
                        <img src={clocktimIcon} />
                        <span>
                          {" "}
                          <span style={{ color: "#565B66" }}>Till:-</span>{" "}
                          10:30AM
                        </span>
                      </span>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <span>
                        <img src={timerIcon} />
                        {(totalSummary.duration / 60).toFixed(2)}
                      </span>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>

            {/* <Box display="flex" justifyContent="space-between" className="tablefooter">
              <Typography variant="h6">Total</Typography>
              <Typography variant="h6">${totalSummary.price}</Typography>
               <Typography variant="h6">Till: 10:30AM</Typography> 
              <Typography variant="h6">{(totalSummary.duration / 60).toFixed(2)}</Typography>
            </Box> */}
          </Box>
        ) : (
          ""
        )}

        <Button
          variant="text"
          onClick={onClickServiceHandler}
          className="addservicebtn appointaddservice"
        >
          <span className="plusservi">+</span>Add Services
        </Button>

        <hr></hr>
        <AddServices
          openServiceModel={openServiceModel}
          addServiceCloseHandler={addServiceCloseHandler}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          totalSummary={totalSummary}
          setTotalSummary={setTotalSummary}
        />

        {/* <TextField
          name="services"
          value={formData.services}
          onChange={handleChange}
          fullWidth
          margin="normal"
          placeholder="Add services"
          sx={{
            borderRadius: "30px",
            backgroundColor: "#f5f5f5",
            "& .MuiOutlinedInput-root": {
              borderRadius: "30px",
            },
          }}
        /> */}
        <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
          <FormControl fullWidth margin="normal">
            <h4 style={{ marginBottom: "8px" }}> Date</h4>
            <DatePicker
              className="inputtxt custominputtext"
              value={formData.date}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField {...params} fullWidth margin="normal" />
              )}
            />
          </FormControl>
          {/* <FormControl fullWidth margin="normal">
            <h4 style={{ marginBottom: "8px" }}>Start Date</h4>
            <DatePicker className="inputtxt"
              value={formData.date}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="normal"

                />
              )}
            />
          </FormControl> */}
        </div>
        <hr></hr>
        <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
          <FormControl fullWidth margin="normal">
            <h4 style={{ marginBottom: "8px" }}>Booked By</h4>
            <div className="bookdropswn">
              <select
                className="inputtxt"
                name="bookedBy"
                value={formData.bookedBy}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="Salon Admin">Salon Admin</option>
              </select>
            </div>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <h4 style={{ marginBottom: "8px" }}>Booking Channel</h4>
            <div className="bookdropswn">
              <select
                className="inputtxt"
                name="bookingChannel"
                value={formData.bookingChannel}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="In Person">In Person</option>
                <option value="Phone">Phone</option>
                <option value="Email">Email</option>
                <option value="Website">Website</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Tiktok">Tik Tok</option>
                <option value="GroupOn">GroupOn</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </FormControl>
        </div>
        <hr></hr>

        <h4 className="repeat">Repeat</h4>
        <RadioGroup
          className="radiotxt"
          row
          name="repeat"
          value={formData.repeat}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        >
          <FormControlLabel value={"off"} control={<Radio />} label={"Off"} />
          <FormControlLabel
            value={"every1week"}
            control={<Radio />}
            label={"Every 1 Week"}
          />
          <FormControlLabel
            value={"every2week"}
            control={<Radio />}
            label={"Every 2 Weeks"}
          />
          <FormControlLabel
            value={"every3week"}
            control={<Radio />}
            label={"Every 3 Weeks"}
          />
          <FormControlLabel
            value={"monthly"}
            control={<Radio />}
            label={"Monthly"}
          />
        </RadioGroup>

        {formData.repeat !== "off" && (
          <Box>
            <Typography variant="h4" className="RepeatOn">
              Repeat On
            </Typography>
            <Box
              sx={{ display: "flex", gap: 0, flexWrap: "wrap", marginTop: 1 }}
              className="daywrp"
            >
              {days.map((day) => (
                <Typography
                  key={day}
                  onClick={() => handleDaySelect(day)}
                  sx={{
                    cursor: "pointer",
                    padding: "8px 16px",
                    borderRadius: "8px",
                    backgroundColor:
                      formData.repeatOn === day ? "#2B2E33" : "#ffffff",
                    color: formData.repeatOn === day ? "#fff" : "#000",
                    textAlign: "center",
                    transition: "background-color 0.3s",
                  }}
                >
                  {day}
                </Typography>
              ))}
            </Box>
          </Box>
        )}

        {formData.repeat !== "off" && (
          <Box sx={{ marginTop: 0 }} className="endinputform">
            <Typography variant="h4" className="endtitl">
              Ends
            </Typography>
            <div className="enddate">
              <RadioGroup
                row
                name="ends"
                value={formData.ends}
                onChange={handleEndsChange}
                sx={{ marginBottom: 2 }}
              >
                <FormControlLabel
                  value="Never"
                  control={<Radio />}
                  label="Never"
                />
                <FormControlLabel value="On" control={<Radio />} label="On" />
              </RadioGroup>
              {formData.ends === "On" && (
                // <TextField
                //   type="date"
                //   name="endDate"
                //   value={formData.endDate}
                //   onChange={handleDateChangeEnds}
                //   required
                //   sx={{ marginTop: 1 }}
                //   placeholder="Select Date"
                //   InputLabelProps={{ shrink: true }}
                //   error={!formData.endDate && formData.ends === "On"}
                //   helperText={!formData.endDate && formData.ends === "On" ? "Date is required" : ""}
                // />
                // <FormControl fullWidth margin="normal">
                <DatePicker
                  className="inputtxt open calender"
                  value={formData.endDate}
                  name="endDate"
                  onChange={handleDateChangeEnds}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth margin="normal" />
                  )}
                />
                // </FormControl>
              )}
            </div>
          </Box>
        )}
        <hr></hr>
        <h4 style={{ marginBottom: "8px" }}>Appointment Note (optional)</h4>

        <TextField
          name="appointmentNote"
          value={formData.appointmentNote}
          onChange={handleChange}
          className="addtextarea"
          placeholder="Enter note here ..."
          fullWidth
          multiline
          rows={3}
          margin="normal"
          shrink
        />
        {/* <Button className="attchfile"
          component="label"
          sx={{
            marginTop: 2,
          }}
        >
          <AttachFileIcon sx={{ marginRight: "8px" }} />
          Attach File
          <input type="file" hidden onChange={handleFileUpload} />
        </Button>
        {formData.attachFile && (
          <Typography variant="body2" sx={{ marginTop: "10px", textAlign: "left" }} className="attachmnt">
            Attached: {formData.attachFile.name}
          </Typography>
        )} */}

        <Box sx={{ display: "flex" }} className="attchmentcontext">
          <Box
            sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}
            className="attchmentcontextinner"
          >
            {uploadedFiles.map((fileObj, index) => (
              <Box
                className="attachmentfile"
                key={index}
                sx={{
                  position: "relative",
                  width: 100,
                  height: 100,
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  overflow: "hidden",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f5f5f5",
                }}
              >
                {fileObj.file.type.startsWith("image/") ? (
                  <>
                    <img
                      src={filetextIcon}
                      alt="file-icon"
                      onClick={() => handleFileClick(fileObj.file)}
                    />
                    <h6>{shortenFileName(fileObj.file.name)}</h6>
                  </>
                ) : fileObj.file.type === "application/pdf" ? (
                  <>
                    <img
                      src={filetextIcon}
                      alt="file-icon"
                      onClick={() => handleFileClick(fileObj.file)}
                    />
                    <h6>{shortenFileName(fileObj.file.name)}</h6>
                  </>
                ) : (
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: "#555",
                      cursor: "pointer",
                    }}
                    onClick={() => handleFileClick(fileObj.file)}
                  >
                    {fileObj.file.name}
                  </Typography>
                )}
                {/* <Typography
                  variant="caption"
                  sx={{
                    position: "absolute",
                    bottom: 8,
                    left: 8,
                    right: 8,
                    textAlign: "center",
                    fontSize: "0.75rem",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    color: "#fff",
                    borderRadius: "4px",
                    padding: "2px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {fileObj.file.name}
                </Typography> */}
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    top: 4,
                    right: 4,
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                  }}
                  onClick={() => removeFile(index)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
          <Box className="attchdiv">
            <Button className="attchfile" component="label">
              <AttachFileIcon sx={{ marginRight: "8px" }} />
              Attach File
              <input type="file" hidden multiple onChange={handleFileUpload} />
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        className="bttmbtn"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          marginTop: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderRadius: "30px",
            color: "#1976d2",
            borderColor: "#1976d2",
            padding: "8px 20px",
          }}
          onClick={() => props.setAddAppointmentToggle(false)}
        >
          Cancel
        </Button>
        <Button
          className="selectbtn"
          type="submit"
          onClick={handleSubmit}
          variant="outlined"
          color="primary"
          sx={{
            borderRadius: "30px",
            color: "#ffffff",

            padding: "8px 20px",
          }}
        >
          Book
        </Button>
      </Box>
      {openCreatCustomerModal && (
        <>
          <AddCustomer
            addCustomerToogle={addCustomerToggle}
            setAddCustomerToggle={setAddCustomerToggle}
          />
        </>
      )}
    </LocalizationProvider>
  );
};

export default AppointmentForm;
