import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";

const CustomTimeline = ({ events, customerTimeline }) => {
  
  return (
    <Timeline
      position="right"
      sx={{
        padding: 0, // Remove Timeline padding
      }}
    >
      {customerTimeline.map((event, index) => (
        <TimelineItem
          key={index}
          sx={{
            padding: 0, // Remove padding from each TimelineItem
            "::before": {
              display: "none", // Remove the default left margin space
            },
          }}
        >
          <TimelineSeparator>
            <TimelineDot
              sx={{
                backgroundColor: "#F0F1F2", // Set background color of the dot
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                flexShrink: 0,
                boxShadow: "none",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F0F1F2",
                }}
              >
                {/* {event.icon} */}
                <svg
                                width="16"
                                height="18"
                                viewBox="0 0 16 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M13.8333 14.8327C13.8333 15.2927 13.4592 15.666 13 15.666H3C2.54083 15.666 2.16667 15.2927 2.16667 14.8327V5.66602C2.16667 5.20602 2.54083 4.83268 3 4.83268V5.66602C3 6.58518 3.7475 7.33268 4.66667 7.33268H11.3333C12.2525 7.33268 13 6.58518 13 5.66602V4.83268C13.4592 4.83268 13.8333 5.20602 13.8333 5.66602V14.8327ZM4.66667 2.33268L11.3333 2.33518V3.16602V5.66602H4.66667V3.16602V2.33268ZM13 3.16602V2.33268C13 1.41352 12.2525 0.666016 11.3333 0.666016H4.66667C3.7475 0.666016 3 1.41352 3 2.33268V3.16602C1.62167 3.16602 0.5 4.28768 0.5 5.66602V14.8327C0.5 16.211 1.62167 17.3327 3 17.3327H13C14.3783 17.3327 15.5 16.211 15.5 14.8327V5.66602C15.5 4.28768 14.3783 3.16602 13 3.16602Z"
                                  fill="#898E99"
                                />
                              </svg>
              </span>
            </TimelineDot>
            {index < customerTimeline.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent sx={{ marginLeft: 0 }}>
            {" "}
            {/* Align content to the left */}
            <Typography
              variant="body1"
              fontWeight="bold"
              color="#2B2E33"
              fontSize={"14px"}
              fontFamily={"Satoshi-Regular !important"}
            >
              {event.action}
            </Typography>
            {event.description && (
              <Paper
                elevation={1}
                sx={{
                  padding: "10px",
                  marginY: "8px",
                  backgroundColor: "#F0F1F2",
                  boxShadow: "none",
                  borderRadius: "12px",
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontSize: "12px",
                    color: "#2B2E33",
                    fontFamily: "Satoshi-Medium !important",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button sx={{ padding: 0, margin: 0, minWidth: "auto" }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6786 7.11944L8.88195 5.32278L10.1806 4.02344L11.9766 5.81944L10.6786 7.11944ZM6.05261 11.7501L4.06795 11.9308L4.24395 9.96011L7.98861 6.21544L9.78595 8.01278L6.05261 11.7501ZM12.9353 4.89211L12.9346 4.89144L11.1093 3.06611C10.6153 2.57344 9.76661 2.55011 9.29861 3.02011L3.30128 9.01744C3.08395 9.23411 2.94928 9.52211 2.92128 9.82678L2.66861 12.6068C2.65128 12.8034 2.72128 12.9981 2.86128 13.1381C2.98728 13.2641 3.15728 13.3334 3.33261 13.3334C3.35328 13.3334 3.37328 13.3328 3.39328 13.3308L6.17328 13.0781C6.47861 13.0501 6.76595 12.9161 6.98261 12.6994L12.9806 6.70144C13.4659 6.21478 13.4453 5.40278 12.9353 4.89211Z"
                        fill="#898E99"
                      />
                    </svg>
                  </Button>
                  {event.description}
                </Typography>
              </Paper>
            )}
            {event.timestamp && (
              <Typography variant="caption" color="text.secondary">
                {event.timestamp}
              </Typography>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default CustomTimeline;
