import React, { useEffect, useRef, useState } from "react";
import Drawer from '@mui/material/Drawer';
import AddCustomerDetail from "./AddCustomerDetail";

export default function CustomerDetail({
    customerDetailToggle,
    setCustomerDetailToggle,
    selectedEventId
}) {

    return (
        <>
            <Drawer
                anchor={"right"}
                open={customerDetailToggle}
                sx={{
                    "& .MuiDrawer-paper":{
                        maxWidth:"800px !important"
                    }
                }}
            >
                <AddCustomerDetail 
                    customerDetailToggle={customerDetailToggle}
                    setCustomerDetailToggle={setCustomerDetailToggle}
                    selectedEventId={selectedEventId} />
            </Drawer>

        </>
    )
}