import { Drawer } from "@mui/material";
import React from "react";
import ModifyWorkingHoursForm from "./ModifyWorkingHoursForm";
const ModifyWorkingHours = ({
  modifyWorkingHoursModal,
  setModifyWorkingHoursModal,
}) => {
  return (
    <>
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            maxWidth: "800px !important",
          },
        }}
        anchor={"right"}
        open={modifyWorkingHoursModal}
      >
        <ModifyWorkingHoursForm
          modifyWorkingHoursModal={modifyWorkingHoursModal}
          setModifyWorkingHoursModal={setModifyWorkingHoursModal}
        />
      </Drawer>
    </>
  );
};

export default ModifyWorkingHours;
