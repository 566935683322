import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Typography,
  ListItemText,
  Chip,
  Box,
  FormControl,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const services = [
  {
    category: "NAILS",
    options: [
      { label: "BIAB with shellac & removal", color: "#FF5733" },
      { label: "BIAB", color: "#33FF57" },
      { label: "BIAB with shellac", color: "#5733FF" },
    ],
  },
  {
    category: "FACIALS",
    options: [{ label: "Skin care", color: "#FFC300" }],
  },
  {
    category: "BODY TREATMENT",
    options: [{ label: "Cosmetic body massage", color: "#33C3FF" }],
  },
];

const SelectWithAccordion = () => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [expanded, setExpanded] = useState("NAILS"); // Keep the first accordion expanded initially
  const dropdownRef = useRef(null); // Reference for the dropdown

  const handleCheckboxChange = (serviceLabel) => {
    setSelectedServices((prev) =>
      prev.includes(serviceLabel)
        ? prev.filter((item) => item !== serviceLabel)
        : [...prev, serviceLabel]
    );
  };

  const handleDeleteChip = (serviceLabel) => {
    setSelectedServices((prev) => prev.filter((item) => item !== serviceLabel));
  };

  const handleAccordionChange = (category) => {
    setExpanded((prev) => (prev === category ? null : category)); // Collapse if already open, otherwise expand
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev); // Toggle dropdown open/close state
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <FormControl fullWidth ref={dropdownRef}>
      <Select
        displayEmpty
        open={dropdownOpen}
        label="Select service(s)"
        onClick={toggleDropdown} // Toggle dropdown when Select is clicked
        onClose={() => setDropdownOpen(false)} // Ensure dropdown can close on outside clicks
        renderValue={() => {
          if (selectedServices.length === 0) return "Select service(s)";
          return null;
        }}
        IconComponent={ExpandMoreIcon}
        sx={{
          backgroundColor: "white",
          "& .MuiSelect-select": {
            border: "1px solid #E1E3E6",
            borderRadius: "22px",
            padding: "8px 20px",
          },
          "& .MuiSelect-icon": {
            right: "8px !important",
            fontSize: "22px",
          },
        }}
        MenuProps={{
          PopoverClasses: {
            root: "custom-popover-class",
          },
          PaperProps: {
            style: { maxHeight: 400, width: 300 }, // Set maximum height for dropdown
            className: "custom-menu-class",
          },
        }}
      >
        <Box style={{ maxHeight: 300, overflowY: "auto" }}>
          {" "}
          {/* Ensure scrollable area */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #E1E3E6",
            }}
          >
            <Checkbox
              onChange={() => setSelectedServices([])}
              label="Select all"
              id="select-all"
              onMouseDown={(e) => e.stopPropagation()}
            />
            <label htmlFor="select-all">Select all</label>
          </Box>
          {services.map((group, index) => (
            <Accordion
              key={index}
              expanded={expanded === group.category} // Expand based on state
              onChange={() => handleAccordionChange(group.category)} // Toggle expansion
              elevation={0}
              style={{ boxShadow: "none", backgroundColor: "transparent" }}
              onMouseDown={(e) => e.stopPropagation()} // Prevent dropdown close on accordion change
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ padding: "0px 10px", minHeight: "32px" }}
                onClick={(e) => e.stopPropagation()} // Prevent dropdown close on tab click
              >
                <Typography variant="body1">{group.category}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "5px 0px" }}>
                {group.options.map((option, idx) => (
                  <MenuItem
                    key={idx}
                    value={option.label}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px",
                    }}
                    onClick={(e) => e.stopPropagation()} // Prevent dropdown close on item click
                  >
                    <Checkbox
                      checked={selectedServices.includes(option.label)}
                      onChange={() => handleCheckboxChange(option.label)}
                    />
                    <FiberManualRecordIcon
                      style={{
                        color: option.color,
                        fontSize: "10px",
                        marginRight: "8px",
                      }}
                    />
                    <ListItemText
                      primary={option.label}
                      primaryTypographyProps={{ sx: { color: "black" } }}
                    />
                  </MenuItem>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Select>

      <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
        {selectedServices.map((serviceLabel) => (
          <Chip
            key={serviceLabel}
            label={serviceLabel}
            onDelete={() => handleDeleteChip(serviceLabel)}
            deleteIcon={<HighlightOffOutlinedIcon sx={{ color: "#15171A" }} />} // Use the custom CloseIcon for delete
            style={{
              backgroundColor: getChipColor(serviceLabel),
              color: "#15171A",
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "Satoshi-Variable",
            }}
          />
        ))}
      </Box>
    </FormControl>
  );
};

const getChipColor = (serviceLabel) => {
  const allOptions = services.flatMap((group) => group.options);
  const service = allOptions.find((option) => option.label === serviceLabel);
  return service?.color || "gray";
};

export default SelectWithAccordion;
