import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const siteId = "S00001";

export const getCustomerNotes = createAsyncThunk(
    "customerNotes/all",
    async (id, { rejectWithValue }) => {

        
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/customernotes/${id}`, {
                headers: {
                    Accept: "application/json",
                },
            });

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const deleteCustomerNotes = createAsyncThunk(
    "customerNotes/delete",
    async (id, { rejectWithValue }) => {
        console.log("delete");
        
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/api/appointmentsnote/deleteNote/${id}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        console.log("response", response);
        
  
        return { id, data: response.data };
      } catch (error) {
        console.log(error,"error");
        
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  );

export const addCustomerNotes = createAsyncThunk(
    "api/appointments",
    async (formData, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/customernotes/create`,
                formData,
                {
                    headers: {
                        // Accept: "application/json",
                        "Content-Type": "multipart/form-data"
                    },
                }
            );

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

const getCustomerNotesSlice = createSlice({
    name: "customerNotes",
    initialState: {
        all: {
            data: {},
            loading: false,
            error: null,
        },
        byId: {
            data: null,
            loading: false,
            error: null,
        },
        delete: {
            loading: false,
            error: null,
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addCustomerNotes.pending, (state) => {
                state.all.loading = true;
                state.all.error = null;
            })
            .addCase(addCustomerNotes.fulfilled, (state, action) => {
                state.all.loading = false;
                state.all.data = action.payload;
            })
            .addCase(addCustomerNotes.rejected, (state, action) => {
                state.all.loading = false;
                state.all.error = action.payload;
            });

        builder
            .addCase(getCustomerNotes.pending, (state) => {
               
                state.byId.loading = true;
                state.byId.error = null;
                state.byId.data = [];
            })
            .addCase(getCustomerNotes.fulfilled, (state, action) => {
                state.byId.loading = false;
                state.byId.data = action.payload;
            })
            .addCase(getCustomerNotes.rejected, (state, action) => {
                state.byId.loading = false;
                state.byId.error = action.payload;
                state.byId.data = [];
            });

               builder
                  .addCase(deleteCustomerNotes.pending, (state) => {
                    state.delete.loading = true;
                    state.delete.error = null;
                
                  })
                //   .addCase(deleteCustomerNotes.fulfilled, (state, action) => {
                //     state.delete.loading = false;
                //     state.all.data.data = state?.all?.data.data?.filter(
                //       (item) => item.id !== action.payload.id
                //     );                   
                //   })
                .addCase(deleteCustomerNotes.fulfilled, (state, action) => {
                    console.log('State before delete:', state);
                    state.delete.loading = false;
                    state.all.data.data = state?.all?.data.data?.filter(
                        (item) => item.id !== action.payload.id
                    );
                })
                  .addCase(deleteCustomerNotes.rejected, (state, action) => {
                    console.log(deleteCustomerNotes.rejected,state,"deleteCustomerNotes.rejected");
                    
                    state.delete.loading = false;
                    state.delete.error = action.payload;
                   
                  });

    },
});

export const getCustomerNotesReducer = getCustomerNotesSlice.reducer;


