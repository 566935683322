import React, { useEffect, useRef, useState } from "react";
import Drawer from '@mui/material/Drawer';
import BlockTimeModal from "./BlockTimeModal";
import "./blocktime.css"

export default function Index({blockTimeModal,setBlockTimeModal}) {
    return (
        <>
            <Drawer
                anchor={"right"}
                open={blockTimeModal}
                sx={{
                    "& .MuiDrawer-paper":{
                        maxWidth:"750px !important"
                    }
                }}
            >
                <BlockTimeModal
                    blockTimeModal={blockTimeModal}
                    setBlockTimeModal={setBlockTimeModal}
                    name ="block" />
            </Drawer>

        </>
    )
}