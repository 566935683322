import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  Button,
  Typography,
  Box,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers";
import "./ModifyWorkingHours.css";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid } from "@material-ui/core";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SelectWithAccordion from "../../components/SelectWithAccordion";
const ModifyWorkingHours = (props) => {
  const [isOpenTimeStart, setIsOpenTimeStart] = useState(false);
  const [isOpenTimeEnd, setIsOpenTimeEnd] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    customer: "",
    services: "",
    date: null,
    startTime: null,
    bookedBy: "",
    bookingChannel: "",
    repeat: "off",
    repeatOn: null,
    ends: "Never",
    endDate: "",
    appointmentNote: "",
    attachFile: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const handleStartTimeChange = (time) => {
    setFormData({ ...formData, startTime: moment(time).format("HH:mm") });
  };

  const handleEndTimeChange = (time) => {
    setFormData({ ...formData, endTime: moment(time).format("HH:mm") });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} className="kk">
      <Typography className="Heading_pop Heading_popOuter_withCloseIocn">
        Modify Working Hours{" "}
        <span className="modifyDate">Mon, 16 May, 2024</span>
      </Typography>
      <span
        className="crosssicon"
        onClick={() => props.setModifyWorkingHoursModal(false)}
      >
        <ClearIcon />
      </span>
      <Box
        className="AppointPop"
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          // maxWidth: "500px",
          margin: "0 auto",
          padding: "20px",
          boxShadow: 3,
          borderRadius: "12px",
          bgcolor: "#ffffff",
          minHeight: "calc(100vh - 175px)",
        }}
      >
        <Box className="bookdrop">
          <FormControl fullWidth margin="normal">
            <Typography variant="h4" style={{ marginBottom: "8px" }}>
              Specialist
            </Typography>
            <div className="bookdropswn">
              <select className="inputtxt" name="bookingChannel">
                <option value="">Select</option>
                <option value="In Person">In Person</option>
                <option value="Phone">Phone</option>
                <option value="Email">Email</option>
                <option value="Website">Website</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Tiktok">Tik Tok</option>
                <option value="GroupOn">GroupOn</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </FormControl>
        </Box>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h4" className="inputtxt custominputtext">
              Start time
            </Typography>
            <TimePicker
              className="rounded-pill"
              sx={{
                "& .MuiInputAdornment-positionEnd": {
                  display: "none",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px  !important",
                  borderRadius: "20px",
                },
                "& .MuiInputBase-input": {
                  padding: "9px 16px !important",
                },
              }}
              open={isOpenTimeStart}
              onOpen={() => setIsOpenTimeStart(true)}
              onClose={() => setIsOpenTimeStart(false)}
              onChange={handleStartTimeChange}
              views={["hours", "minutes"]}
              slotProps={{
                textField: {
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={() => setIsOpenTimeStart(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <AccessTimeIcon />
                      </InputAdornment>
                    ),
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" className="inputtxt custominputtext">
              End time
            </Typography>
            <TimePicker
              className="rounded-pill"
              sx={{
                "& .MuiInputAdornment-positionEnd": {
                  display: "none",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px  !important",
                  borderRadius: "20px",
                },
                "& .MuiInputBase-input": {
                  padding: "9px 16px !important",
                },
              }}
              open={isOpenTimeEnd}
              onOpen={() => setIsOpenTimeEnd(true)}
              onClose={() => setIsOpenTimeEnd(false)}
              onChange={handleEndTimeChange}
              views={["hours", "minutes"]}
              slotProps={{
                textField: {
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={() => setIsOpenTimeEnd(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <AccessTimeIcon />
                      </InputAdornment>
                    ),
                  },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h4" className="inputtxt custominputtext">
                  Start time
                </Typography>
                <TimePicker
                  className="rounded-pill"
                  sx={{
                    "& .MuiInputAdornment-positionEnd": {
                      display: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px  !important",
                      borderRadius: "20px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "9px 16px !important",
                    },
                  }}
                  open={isOpenTimeStart}
                  onOpen={() => setIsOpenTimeStart(true)}
                  onClose={() => setIsOpenTimeStart(false)}
                  onChange={handleStartTimeChange}
                  views={["hours", "minutes"]}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      InputProps: {
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={() => setIsOpenTimeStart(true)}
                            style={{ cursor: "pointer" }}
                          >
                            <AccessTimeIcon />
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4" className="inputtxt custominputtext">
                  End time
                </Typography>
                <TimePicker
                  className="rounded-pill"
                  sx={{
                    "& .MuiInputAdornment-positionEnd": {
                      display: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px  !important",
                      borderRadius: "20px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "9px 16px !important",
                    },
                  }}
                  open={isOpenTimeEnd}
                  onOpen={() => setIsOpenTimeEnd(true)}
                  onClose={() => setIsOpenTimeEnd(false)}
                  onChange={handleEndTimeChange}
                  views={["hours", "minutes"]}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      InputProps: {
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={() => setIsOpenTimeEnd(true)}
                            style={{ cursor: "pointer" }}
                          >
                            <AccessTimeIcon />
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} alignSelf={"end"}>
            <Box
              sx={{
                marginTop: "28px",
                minHeight: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={""}>
                <CloseIcon
                  sx={{ color: "#F05252!important", fontSize: "22px" }}
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box className="addservicebtn my-3">
          <Button variant="text" className="p-0">
            <span className="plusservi">+</span>Add Services
          </Button>
        </Box>
        <Box className="applyallmonday">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label="Apply to all Mondays"
              sx={{
                display: "inline-flex",
                "& .MuiCheckbox-root": {
                  padding: "4.5px 8px !important",
                  marginLeft: " 2px",
                  "& .MuiSvgIcon-root": {
                    fill: " #E1E3E6 !important",
                    width: "20px !important",
                    height: "20px !important",
                  },
                  "&.Mui-checked": {
                    "& .MuiSvgIcon-root": {
                      fill: "#2B2E33 !important",
                    },
                  },
                },
                "& .MuiFormControlLabel-label": {
                  color: "#15171A",
                  fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "Satoshi-Variable",
                },
              }}
            />
          </FormGroup>
        </Box>
        <hr />

        <Box className="bookdrop">
          <FormControl fullWidth margin="normal">
            <Typography variant="h4" style={{ marginBottom: "8px" }}>
              Services offered
            </Typography>
            <SelectWithAccordion />
          </FormControl>
        </Box>
      </Box>
      <Box
        className="bttmbtn"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          marginTop: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderRadius: "30px",
            color: "#1976d2",
            borderColor: "#1976d2",
            padding: "8px 20px",
          }}
          onClick={() => props.setModifyWorkingHoursModal(false)}
        >
          Cancel
        </Button>
        <Button
          className="selectbtn"
          type="submit"
          onClick={handleSubmit}
          variant="outlined"
          color="primary"
          sx={{
            borderRadius: "30px",
            color: "#ffffff",

            padding: "8px 20px",
          }}
        >
          Book
        </Button>
      </Box>
    </LocalizationProvider>
  );
};

export default ModifyWorkingHours;
