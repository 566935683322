import React, { useEffect, useRef, useState } from "react";
import Drawer from '@mui/material/Drawer';
import CreateCustomer from "./createCustomer";

export default function Index({
    addCustomerToogle,
    setAddCustomerToggle
}) {

    return (
        <>
            <Drawer
                sx={{
                    "& .MuiDrawer-paper":{
                        maxWidth:"800px !important"
                    }
                }}
                anchor={"right"}
                open={addCustomerToogle}
            >
                <CreateCustomer 
                    addCustomerToogle={addCustomerToogle}
                    setAddCustomerToggle={setAddCustomerToggle} />
            </Drawer>

        </>
    )
}