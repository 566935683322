import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  TextField,
  MenuItem,
  FormControl,
  Select,
  Button,
  Typography,
  Box,
  Divider,
  Radio,
  RadioGroup,
  IconButton,
  InputLabel,
  MenuList,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
} from "@mui/material";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./appointment.css";
import { Grid } from "@material-ui/core";
import { fetchSpecialists } from "../store/specialist";
import { addBlockTime, fetchAllBlockTime } from "../store/blocktime";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const BlockTimeModal = ({ blockTimeModal, setBlockTimeModal }) => {
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [formData, setFormData] = useState({
    repeat: "off",
    repeatOn: null,
    ends: "Never",
  });
  const [allDay, setAllDay] = useState(false);
  const [selectedSpecialist, setSelectedSpecialist] = useState(null);
  const [isOpenStartDate, setIsOpenStartDate] = useState(false);
  const [isOpenEndDate, setIsOpenEndDate] = useState(false);
  const [isOpenTimeStart, setIsOpenTimeStart] = useState(false);
  const [isOpenTimeEnd, setIsOpenTimeEnd] = useState(false);
  const { data: specialistData } = useSelector(
    (state) => state.specialists.all
  );

  const handleDateChangeEnds = (value) => {
    setFormData({ ...formData, ends: moment(value).format("YYYY-MM-DD") });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, startDate: moment(date).format("YYYY-MM-DD") });
  };
  const handleEndDateChange = (date) => {
    setFormData({ ...formData, endDate: moment(date).format("YYYY-MM-DD") });
  };

  const handleStartTimeChange = (time) => {
    setFormData({ ...formData, startTime: moment(time).format("HH:mm") });
  };

  const handleEndTimeChange = (time) => {
    setFormData({ ...formData, endTime: moment(time).format("HH:mm") });
  };

  const handleNotesChange = (event) => {
    setFormData({ ...formData, description: event.target.value });
  };

  const handleSubmit = async () => {
    const formPayload = new FormData();

    let checkValidation = true;
    if (!formData?.reason) {
      toast.error("Please select reason", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (!formData?.specialistId) {
      toast.error("Please select specialist", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (!formData?.startDate) {
      toast.error("Please select start date", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (!formData?.endDate) {
      toast.error("Please select end date", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (!allDay && !formData?.startTime) {
      toast.error("Please select start time", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (!allDay && !formData?.endTime) {
      toast.error("Please select end time", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (formData?.repeat !== "off") {
      if (!formData?.repeatOn) {
        toast.error("Please select repeat on", {
          toastId: "form-validation",
        });
        checkValidation = false;
      }
      if (formData?.endsType == "On" && !formData?.ends) {
        toast.error("Please select ends date", {
          toastId: "form-validation",
        });
        checkValidation = false;
      }
    }
    if (checkValidation) {
      formPayload.append("reason", formData?.reason);
      formPayload.append("specialist", formData?.specialistId);
      formPayload.append("startDate", formData?.startDate);
      formPayload.append("endDate", formData?.endDate);
      formPayload.append("isAllDay", allDay);
      formPayload.append("repeat", formData?.repeat);
      formPayload.append("description", formData?.description || "");
      if (!allDay) {
        formPayload.append("startTime", formData?.startTime);
        formPayload.append("endTime", formData?.endTime);
      } else {
        formPayload.append("startTime", "08:00");
        formPayload.append("endTime", "20:00");
      }
      if (formData?.repeat !== "off") {
        formPayload.append("repeatOn", formData?.repeatOn);
        formPayload.append("endsType", formData?.endsType);
        if (formData?.endsType == "On") {
          formPayload.append("ends", formData?.ends);
        }
      }

      try {
        let response = await dispatch(addBlockTime(formPayload));
        if (response?.payload?.success) {
          toast.success(response?.payload?.message, { toastId: "api-success" });
          dispatch(fetchAllBlockTime());
          setBlockTimeModal(false);
        } else {
          toast.error(response?.payload?.message, { toastId: "api-success" });
        }
      } catch (err) {
        toast.error(err.message, {
          toastId: "form-validation",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDaySelect = (day) => {
    setFormData({ ...formData, repeatOn: day });
  };

  const handleEndsChange = (event) => {
    setFormData({ ...formData, endsType: event.target.value });
  };

  const handleSelectReason = (val) => {
    setFormData({ ...formData, reason: val });
  };

  const handleSpecilistSelect = (event) => {
    let val = event.target.value;
    setFormData({ ...formData, specialistId: val });
    let tempSpec = specialistData.find((dt) => dt._id == val);
    setSelectedSpecialist(tempSpec);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#65C466",
          opacity: 1,
          border: 0,
          ...theme.applyStyles("dark", {
            backgroundColor: "#2ECA45",
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        ...theme.applyStyles("dark", {
          color: theme.palette.grey[600],
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
        ...theme.applyStyles("dark", {
          opacity: 0.3,
        }),
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      ...theme.applyStyles("dark", {
        backgroundColor: "#39393D",
      }),
    },
  }));
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} className="kk">
      <Typography className="Heading_pop">
        Block time {selectedSpecialist ? `for ${selectedSpecialist?.first_name} ${selectedSpecialist?.last_name}` : ""}
      </Typography>
      <span className="crosssicon" onClick={() => setBlockTimeModal(false)}>
        <ClearIcon />
      </span>
      <Box
        className="AppointPop"
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          // maxWidth: "500px",
          margin: "0 auto",
          padding: "20px",
          boxShadow: 3,
          borderRadius: "12px",
          bgcolor: "#ffffff",
        }}
      >
        <Box>
          <Typography variant="h4">Reason</Typography>
          <Grid container spacing={1}>
            <Grid item>
              <Box
                className="reasonBoxList"
                onClick={() => handleSelectReason("Lunch")}
              >
                <input type="radio" name="reason" id="lunch" />
                <label htmlFor="lunch">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask x="0" y="1" width="24" height="22">
                      <path d="M0 1H24V23H0V1Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_5526_27635)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.859375 16.2969C0.859375 17.7984 2.07656 19.0156 3.57812 19.0156H20.4219C21.9234 19.0156 23.1406 17.7984 23.1406 16.2969C23.1406 10.0713 18.2696 4.98438 12 4.98438C5.73042 4.98438 0.859375 10.0713 0.859375 16.2969ZM3.57812 17.0156C3.18116 17.0156 2.85938 16.6938 2.85938 16.2969C2.85938 11.1316 6.87865 6.98438 12 6.98438C17.1213 6.98438 21.1406 11.1316 21.1406 16.2969C21.1406 16.6938 20.8188 17.0156 20.4219 17.0156H3.57812Z"
                        fill="#2B2E33"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11 12.8594C11 13.4117 11.4477 13.8594 12 13.8594H13.7188C14.271 13.8594 14.7188 13.4117 14.7188 12.8594C14.7188 12.3071 14.271 11.8594 13.7188 11.8594H13V11.1406C13 10.5883 12.5523 10.1406 12 10.1406C11.4477 10.1406 11 10.5883 11 11.1406V12.8594Z"
                        fill="#2B2E33"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.859375 21.4531C0.859375 22.0054 1.30709 22.4531 1.85938 22.4531H22.1406C22.6929 22.4531 23.1406 22.0054 23.1406 21.4531C23.1406 20.9008 22.6929 20.4531 22.1406 20.4531H1.85938C1.30709 20.4531 0.859375 20.9008 0.859375 21.4531Z"
                        fill="#2B2E33"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.28125 2.54688C9.28125 3.09916 9.72897 3.54688 10.2812 3.54688H13.7188C14.271 3.54688 14.7188 3.09916 14.7188 2.54688C14.7188 1.99459 14.271 1.54688 13.7188 1.54688H10.2812C9.72897 1.54688 9.28125 1.99459 9.28125 2.54688Z"
                        fill="#2B2E33"
                      />
                    </g>
                  </svg>
                  <Typography variant="span">Lunch</Typography>
                </label>
              </Box>
            </Grid>
            <Grid item>
              <Box
                className="reasonBoxList"
                onClick={() => handleSelectReason("Holiday")}
              >
                <input type="radio" name="reason" id="Holiday" />
                <label htmlFor="Holiday">
                  <svg
                    width="24"
                    height="20"
                    viewBox="0 0 24 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M23.293 15.2904C23.4848 14.7725 23.2205 14.1972 22.7026 14.0053C22.6201 13.9748 22.5365 13.9437 22.452 13.9123C20.9168 13.3415 19.0679 12.654 17.1777 12.654C16.1032 12.654 15.1053 13.0329 14.3007 13.3383C14.2784 13.3468 14.2563 13.3552 14.2342 13.3635C13.3505 13.6987 12.6743 13.9431 12.0001 13.9431C11.3257 13.9431 10.6496 13.6987 9.76583 13.3635C9.74381 13.3552 9.72165 13.3468 9.69934 13.3383C8.89478 13.0329 7.89686 12.654 6.82241 12.654C4.88012 12.654 3.06873 13.3372 1.42207 13.9583C1.37865 13.9747 1.33534 13.9911 1.29214 14.0073C0.775342 14.2021 0.514282 14.7789 0.70905 15.2957C0.903818 15.8125 1.48066 16.0736 1.99746 15.8788C3.75338 15.2171 5.25879 14.654 6.82241 14.654C7.49672 14.654 8.17286 14.8984 9.05664 15.2336C9.07865 15.2419 9.10081 15.2503 9.12312 15.2588C9.92769 15.5642 10.9256 15.9431 12.0001 15.9431C13.0745 15.9431 14.0724 15.5643 14.8769 15.2588C14.8993 15.2503 14.9214 15.2419 14.9434 15.2336C15.8272 14.8984 16.5034 14.654 17.1777 14.654C18.6982 14.654 20.2395 15.2253 21.8715 15.8303C21.9169 15.8471 21.9624 15.864 22.0079 15.8808C22.5258 16.0727 23.1012 15.8083 23.293 15.2904Z"
                      fill="#898E99"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.7151 14.1095C18.2593 14.2035 18.7768 13.8386 18.8708 13.2944C18.9385 12.9022 18.9727 12.505 18.9727 12.1069C18.9727 8.25595 15.8509 5.13421 12 5.13421C8.14909 5.13421 5.02734 8.25595 5.02734 12.1069C5.02734 12.4958 5.0599 12.8841 5.12466 13.2676C5.21661 13.8122 5.73262 14.1792 6.27719 14.0872C6.82177 13.9953 7.1887 13.4792 7.09675 12.9347C7.05056 12.6611 7.02734 12.3842 7.02734 12.1069C7.02734 9.36052 9.25366 7.13421 12 7.13421C14.7463 7.13421 16.9727 9.36052 16.9727 12.1069C16.9727 12.3907 16.9483 12.674 16.9 12.9538C16.806 13.4981 17.1709 14.0155 17.7151 14.1095Z"
                      fill="#898E99"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.644531 12.1072C0.644531 12.6595 1.09225 13.1072 1.64453 13.1072H2.97656C3.52885 13.1072 3.97656 12.6595 3.97656 12.1072C3.97656 11.5549 3.52885 11.1072 2.97656 11.1072H1.64453C1.09225 11.1072 0.644531 11.5549 0.644531 12.1072Z"
                      fill="#898E99"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.05151 8.09529C5.32766 7.617 5.16378 7.00541 4.68548 6.72926L3.5319 6.06325C3.05361 5.78711 2.44202 5.95099 2.16588 6.42928C1.88974 6.90757 2.05361 7.51916 2.53191 7.7953L3.68549 8.46132C4.16378 8.73746 4.77537 8.57358 5.05151 8.09529Z"
                      fill="#898E99"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.98845 5.15858C8.46674 4.88244 8.63062 4.27085 8.35448 3.79256L7.68846 2.63898C7.41232 2.16069 6.80073 1.99681 6.32244 2.27295C5.84414 2.54909 5.68026 3.16068 5.95641 3.63897L6.62242 4.79255C6.89856 5.27085 7.51015 5.43473 7.98845 5.15858Z"
                      fill="#898E99"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 4.08374C12.5523 4.08374 13 3.63603 13 3.08374V1.75171C13 1.19942 12.5523 0.751709 12 0.751709C11.4477 0.751709 11 1.19942 11 1.75171V3.08374C11 3.63603 11.4477 4.08374 12 4.08374Z"
                      fill="#898E99"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.0119 5.15858C16.4902 5.43473 17.1018 5.27085 17.3779 4.79255L18.0439 3.63897C18.3201 3.16068 18.1562 2.54909 17.6779 2.27295C17.1996 1.99681 16.588 2.16069 16.3119 2.63898L15.6459 3.79256C15.3697 4.27085 15.5336 4.88244 16.0119 5.15858Z"
                      fill="#898E99"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.9486 8.09529C19.2247 8.57358 19.8363 8.73746 20.3146 8.46132L21.4682 7.7953C21.9465 7.51916 22.1104 6.90757 21.8342 6.42928C21.5581 5.95099 20.9465 5.78711 20.4682 6.06325L19.3146 6.72926C18.8363 7.00541 18.6725 7.617 18.9486 8.09529Z"
                      fill="#898E99"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.0234 12.1072C20.0234 12.6595 20.4712 13.1072 21.0234 13.1072H22.3555C22.9078 13.1072 23.3555 12.6595 23.3555 12.1072C23.3555 11.5549 22.9078 11.1072 22.3555 11.1072H21.0234C20.4712 11.1072 20.0234 11.5549 20.0234 12.1072Z"
                      fill="#898E99"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.26637 18.5761C4.44727 19.0979 5.01693 19.3742 5.53875 19.1933C5.80522 19.101 6.04062 19.0178 6.25172 18.9432C7.38663 18.5421 7.8196 18.3891 8.60574 18.3891C8.96064 18.3891 9.3459 18.5236 9.9461 18.753C9.96612 18.7606 9.98645 18.7684 10.0071 18.7763C10.5271 18.9754 11.2401 19.2485 12.0003 19.2485C12.7605 19.2485 13.4735 18.9754 13.9935 18.7763C14.0141 18.7684 14.0344 18.7606 14.0544 18.753C14.6546 18.5236 15.0399 18.3891 15.3948 18.3891C16.3197 18.3891 17.4833 18.8211 18.4333 19.183C18.9494 19.3796 19.5272 19.1206 19.7238 18.6045C19.9204 18.0884 19.6614 17.5106 19.1453 17.314C19.1206 17.3046 19.0953 17.295 19.0695 17.2851C18.2023 16.9543 16.7209 16.3891 15.3948 16.3891C14.6346 16.3891 13.9216 16.6622 13.4016 16.8613C13.381 16.8692 13.3607 16.877 13.3406 16.8847C12.7404 17.114 12.3552 17.2485 12.0003 17.2485C11.6454 17.2485 11.2601 17.114 10.6599 16.8847C10.6399 16.877 10.6196 16.8692 10.5989 16.8613C10.079 16.6622 9.36592 16.3891 8.60574 16.3891C7.45336 16.3891 6.67378 16.6673 5.53325 17.0742C5.32962 17.1469 5.11448 17.2237 4.88367 17.3037C4.36185 17.4846 4.08548 18.0542 4.26637 18.5761Z"
                      fill="#898E99"
                    />
                  </svg>

                  <Typography variant="span">Holiday</Typography>
                </label>
              </Box>
            </Grid>
            <Grid item>
              <Box
                className="reasonBoxList"
                onClick={() => handleSelectReason("Sick")}
              >
                <input type="radio" name="reason" id="Sick" />
                <label htmlFor="Sick">
                  <svg
                    width="20"
                    height="24"
                    viewBox="0 0 20 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.5833 6.16724C7.18636 6.16724 6.85742 5.83832 6.85742 5.44131V4.71543H7.92856C8.48084 4.71543 8.92856 4.26771 8.92856 3.71543C8.92856 3.12386 9.40805 2.64433 9.99965 2.64433C10.5911 2.64433 11.0707 3.12387 11.0707 3.71543C11.0707 4.26771 11.5184 4.71543 12.0707 4.71543H13.1418V5.44131C13.1418 5.83832 12.8128 6.16724 12.4159 6.16724H7.5833ZM4.85742 5.44131C4.85742 6.94283 6.08172 8.16724 7.5833 8.16724H12.4159C13.9175 8.16724 15.1418 6.94283 15.1418 5.44131V3.71543C15.1418 3.16314 14.694 2.71543 14.1418 2.71543H12.9042C12.4894 1.51027 11.3456 0.644333 9.99965 0.644333C8.65356 0.644333 7.50985 1.51027 7.09504 2.71543H5.85742C5.30514 2.71543 4.85742 3.16314 4.85742 3.71543V5.44131Z"
                      fill="#898E99"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.715332 20.6295C0.715332 22.131 1.93961 23.3555 3.44117 23.3555H16.5581C18.0595 23.3555 19.284 22.1311 19.284 20.6295V5.44155C19.284 3.93992 18.0595 2.71567 16.5581 2.71567H12.0706C11.5183 2.71567 11.0706 3.16338 11.0706 3.71567C11.0706 4.26795 11.5183 4.71567 12.0706 4.71567H16.5581C16.9551 4.71567 17.284 5.04465 17.284 5.44155V20.6295C17.284 21.0265 16.9551 21.3555 16.5581 21.3555H3.44117C3.04428 21.3555 2.71533 21.0266 2.71533 20.6295V5.44155C2.71533 5.04455 3.04425 4.71567 3.44117 4.71567H7.92831C8.4806 4.71567 8.92831 4.26795 8.92831 3.71567C8.92831 3.16338 8.4806 2.71567 7.92831 2.71567H3.44117C1.93965 2.71567 0.715332 3.94002 0.715332 5.44155V20.6295Z"
                      fill="#898E99"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.71899 18.9037C7.71899 19.4008 8.12194 19.8037 8.61899 19.8037H11.3804C11.8775 19.8037 12.2804 19.4008 12.2804 18.9037V17.0423H14.1419C14.639 17.0423 15.0419 16.6393 15.0419 16.1423V13.3808C15.0419 12.8838 14.639 12.4808 14.1419 12.4808H12.2804V10.6193C12.2804 10.1223 11.8775 9.71934 11.3804 9.71934H8.61899C8.12194 9.71934 7.71899 10.1223 7.71899 10.6193V12.4808H5.85752C5.36046 12.4808 4.95752 12.8838 4.95752 13.3808V16.1423C4.95752 16.6393 5.36046 17.0423 5.85752 17.0423H7.71899V18.9037ZM9.51899 18.0037V16.1423C9.51899 15.6452 9.11605 15.2423 8.61899 15.2423H6.75752V14.2808H8.61899C9.11605 14.2808 9.51899 13.8779 9.51899 13.3808V11.5193H10.4804V13.3808C10.4804 13.8779 10.8834 14.2808 11.3804 14.2808H13.2419V15.2423H11.3804C10.8834 15.2423 10.4804 15.6452 10.4804 16.1423V18.0037H9.51899Z"
                      fill="#898E99"
                    />
                  </svg>
                  <Typography variant="span">Sick</Typography>
                </label>
              </Box>
            </Grid>
            <Grid item>
              <Box
                className="reasonBoxList"
                onClick={() => handleSelectReason("Training")}
              >
                <input type="radio" name="reason" id="Training" />
                <label htmlFor="Training">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11 3C11 3.553 10.552 4 10 4C9.448 4 9 3.553 9 3V1C9 0.447 9.448 0 10 0C10.552 0 11 0.447 11 1V3ZM11 13.4598C11 13.1028 11.191 12.7718 11.501 12.5928C12.426 12.0618 13 11.0678 13 9.9998C13 8.3458 11.654 6.9998 10 6.9998C8.346 6.9998 7 8.3458 7 9.9998C7 11.0678 7.574 12.0618 8.499 12.5928C8.809 12.7718 9 13.1028 9 13.4598V17.9998H11V13.4598ZM5 9.9998C5 7.2428 7.243 4.9998 10 4.9998C12.757 4.9998 15 7.2428 15 9.9998C15 11.5788 14.246 13.0618 13 13.9958V17.9998C13 19.1028 12.103 19.9998 11 19.9998H9C7.897 19.9998 7 19.1028 7 17.9998V13.9958C5.753 13.0618 5 11.5788 5 9.9998ZM19 9H17C16.447 9 16 9.447 16 10C16 10.553 16.447 11 17 11H19C19.553 11 20 10.553 20 10C20 9.447 19.553 9 19 9ZM1 9H3C3.552 9 4 9.447 4 10C4 10.553 3.552 11 3 11H1C0.448 11 0 10.553 0 10C0 9.447 0.448 9 1 9ZM5.6597 4.418L4.2207 3.028C3.8237 2.645 3.1917 2.657 2.8067 3.054C2.4227 3.45 2.4337 4.084 2.8307 4.468L4.2697 5.857C4.4647 6.045 4.7147 6.138 4.9647 6.138C5.2267 6.138 5.4877 6.035 5.6837 5.832C6.0677 5.436 6.0567 4.802 5.6597 4.418ZM15.7794 3.0297C16.1754 2.6467 16.8114 2.6577 17.1924 3.0537C17.5764 3.4497 17.5654 4.0837 17.1684 4.4667L15.7294 5.8567C15.5364 6.0437 15.2854 6.1377 15.0354 6.1377C14.7734 6.1377 14.5114 6.0347 14.3164 5.8317C13.9324 5.4357 13.9434 4.8017 14.3404 4.4187L15.7794 3.0297Z"
                      fill="#898E99"
                    />
                  </svg>
                  <Typography variant="span">Training</Typography>
                </label>
              </Box>
            </Grid>
            <Grid item>
              <Box
                className="reasonBoxList"
                onClick={() => handleSelectReason("Other")}
              >
                <input type="radio" name="reason" id="Other" />
                <label htmlFor="Other">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 16V12H16L16.001 16H12ZM16 10H12C10.897 10 10 10.897 10 12V16C10 17.103 10.897 18 12 18H16C17.103 18 18 17.103 18 16V12C18 10.897 17.103 10 16 10ZM2 16V12H6L6.001 16H2ZM6 10H2C0.897 10 0 10.897 0 12V16C0 17.103 0.897 18 2 18H6C7.103 18 8 17.103 8 16V12C8 10.897 7.103 10 6 10ZM12 6V2H16L16.001 6H12ZM16 0H12C10.897 0 10 0.897 10 2V6C10 7.103 10.897 8 12 8H16C17.103 8 18 7.103 18 6V2C18 0.897 17.103 0 16 0ZM2 6V2H6L6.001 6H2ZM6 0H2C0.897 0 0 0.897 0 2V6C0 7.103 0.897 8 2 8H6C7.103 8 8 7.103 8 6V2C8 0.897 7.103 0 6 0Z"
                      fill="#898E99"
                    />
                  </svg>

                  <Typography variant="span">Other</Typography>
                </label>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <hr />
        <Box>
          <Typography variant="h4">Specialist</Typography>
          <select
            className="form-select rounded-pill"
            value={formData?.specialistId || ""}
            onChange={handleSpecilistSelect}
          >
            <option value={""}>Select</option>
            {specialistData.map((dt) => (
              <option key={dt._id} value={dt._id}>
                {dt.first_name} {dt.last_name}
              </option>
            ))}
          </select>
        </Box>
        <hr />
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={!allDay ? 5 : 10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4" className="inputtxt custominputtext">
                    Start Date
                  </Typography>
                  <DatePicker
                    className="rounded-pill"
                    sx={{
                      "& .MuiInputAdornment-positionEnd": {
                        display: "none",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px  !important",
                        borderRadius: "20px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "9px 16px !important",
                      },
                    }}
                    minDate={new Date()}
                    open={isOpenStartDate}
                    onOpen={() => setIsOpenStartDate(true)}
                    onClose={() => setIsOpenStartDate(false)}
                    onChange={handleDateChange}
                    // slots={{ openPickerIcon: null }} // Removes the default calendar icon
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        InputProps: {
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              onClick={() => setIsOpenStartDate(true)}
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                width="12"
                                height="15"
                                viewBox="0 0 12 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M2.66667 10.1666C2.66667 9.79992 2.96667 9.49992 3.33333 9.49992C3.7 9.49992 4 9.79992 4 10.1666C4 10.5333 3.7 10.8333 3.33333 10.8333C2.96667 10.8333 2.66667 10.5333 2.66667 10.1666ZM6 9.49992H8.66667C9.03333 9.49992 9.33333 9.79992 9.33333 10.1666C9.33333 10.5333 9.03333 10.8333 8.66667 10.8333H6C5.63333 10.8333 5.33333 10.5333 5.33333 10.1666C5.33333 9.79992 5.63333 9.49992 6 9.49992ZM10 12.8333H2C1.63267 12.8333 1.33333 12.5339 1.33333 12.1666V8.16659H10.6667V12.1666C10.6667 12.5339 10.3673 12.8333 10 12.8333ZM2 3.49992H2.66667V4.16659C2.66667 4.53325 2.96667 4.83325 3.33333 4.83325C3.7 4.83325 4 4.53325 4 4.16659V3.49992H8V4.16659C8 4.53325 8.3 4.83325 8.66667 4.83325C9.03333 4.83325 9.33333 4.53325 9.33333 4.16659V3.49992H10C10.3673 3.49992 10.6667 3.79925 10.6667 4.16659V6.83325H1.33333V4.16659C1.33333 3.79925 1.63267 3.49992 2 3.49992ZM10 2.16659H9.33333V1.49992C9.33333 1.13325 9.03333 0.833252 8.66667 0.833252C8.3 0.833252 8 1.13325 8 1.49992V2.16659H4V1.49992C4 1.13325 3.7 0.833252 3.33333 0.833252C2.96667 0.833252 2.66667 1.13325 2.66667 1.49992V2.16659H2C0.897333 2.16659 0 3.06392 0 4.16659V12.1666C0 13.2693 0.897333 14.1666 2 14.1666H10C11.1027 14.1666 12 13.2693 12 12.1666V4.16659C12 3.06392 11.1027 2.16659 10 2.16659Z"
                                  fill="#1D2023"
                                />
                              </svg>
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" className="inputtxt custominputtext">
                    End Date
                  </Typography>
                  <DatePicker
                    className="rounded-pill"
                    sx={{
                      "& .MuiInputAdornment-positionEnd": {
                        display: "none",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px  !important",
                        borderRadius: "20px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "9px 16px !important",
                      },
                    }}
                    minDate={new Date()}
                    open={isOpenEndDate}
                    onOpen={() => setIsOpenEndDate(true)}
                    onClose={() => setIsOpenEndDate(false)}
                    onChange={handleEndDateChange}
                    // slots={{ openPickerIcon: null }} // Removes the default calendar icon
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        InputProps: {
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              onClick={() => setIsOpenEndDate(true)}
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                width="12"
                                height="15"
                                viewBox="0 0 12 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M2.66667 10.1666C2.66667 9.79992 2.96667 9.49992 3.33333 9.49992C3.7 9.49992 4 9.79992 4 10.1666C4 10.5333 3.7 10.8333 3.33333 10.8333C2.96667 10.8333 2.66667 10.5333 2.66667 10.1666ZM6 9.49992H8.66667C9.03333 9.49992 9.33333 9.79992 9.33333 10.1666C9.33333 10.5333 9.03333 10.8333 8.66667 10.8333H6C5.63333 10.8333 5.33333 10.5333 5.33333 10.1666C5.33333 9.79992 5.63333 9.49992 6 9.49992ZM10 12.8333H2C1.63267 12.8333 1.33333 12.5339 1.33333 12.1666V8.16659H10.6667V12.1666C10.6667 12.5339 10.3673 12.8333 10 12.8333ZM2 3.49992H2.66667V4.16659C2.66667 4.53325 2.96667 4.83325 3.33333 4.83325C3.7 4.83325 4 4.53325 4 4.16659V3.49992H8V4.16659C8 4.53325 8.3 4.83325 8.66667 4.83325C9.03333 4.83325 9.33333 4.53325 9.33333 4.16659V3.49992H10C10.3673 3.49992 10.6667 3.79925 10.6667 4.16659V6.83325H1.33333V4.16659C1.33333 3.79925 1.63267 3.49992 2 3.49992ZM10 2.16659H9.33333V1.49992C9.33333 1.13325 9.03333 0.833252 8.66667 0.833252C8.3 0.833252 8 1.13325 8 1.49992V2.16659H4V1.49992C4 1.13325 3.7 0.833252 3.33333 0.833252C2.96667 0.833252 2.66667 1.13325 2.66667 1.49992V2.16659H2C0.897333 2.16659 0 3.06392 0 4.16659V12.1666C0 13.2693 0.897333 14.1666 2 14.1666H10C11.1027 14.1666 12 13.2693 12 12.1666V4.16659C12 3.06392 11.1027 2.16659 10 2.16659Z"
                                  fill="#1D2023"
                                />
                              </svg>
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
            {!allDay ? (
              <Grid item xs={12} sm={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      className="inputtxt custominputtext"
                    >
                      Start time
                    </Typography>
                    <TimePicker
                      className="rounded-pill"
                      sx={{
                        "& .MuiInputAdornment-positionEnd": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderWidth: "1px  !important",
                          borderRadius: "20px",
                        },
                        "& .MuiInputBase-input": {
                          padding: "9px 16px !important",
                        },
                      }}
                      open={isOpenTimeStart}
                      onOpen={() => setIsOpenTimeStart(true)}
                      onClose={() => setIsOpenTimeStart(false)}
                      onChange={handleStartTimeChange}
                      views={["hours", "minutes"]}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          InputProps: {
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                onClick={() => setIsOpenTimeStart(true)}
                                style={{ cursor: "pointer" }}
                              >
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      className="inputtxt custominputtext"
                    >
                      End time
                    </Typography>
                    <TimePicker
                      className="rounded-pill"
                      sx={{
                        "& .MuiInputAdornment-positionEnd": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderWidth: "1px  !important",
                          borderRadius: "20px",
                        },
                        "& .MuiInputBase-input": {
                          padding: "9px 16px !important",
                        },
                      }}
                      open={isOpenTimeEnd}
                      onOpen={() => setIsOpenTimeEnd(true)}
                      onClose={() => setIsOpenTimeEnd(false)}
                      onChange={handleEndTimeChange}
                      views={["hours", "minutes"]}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          InputProps: {
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                onClick={() => setIsOpenTimeEnd(true)}
                                style={{ cursor: "pointer" }}
                              >
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            <Grid xs={12} sm={2}>
              <Box sx={{ marginTop: { md: "36px", sm: "20px" } }}>
                <FormControlLabel
                  sx={{
                    "&.MuiFormControlLabel-root": {
                      marginLeft: "0px !important", // Overrides the default margin-left
                      marginRight: "0px !important", // Overrides the default margin-left
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      color: "#15171A",
                      fontWeight: "500",
                      fontFamily: "Satoshi-Medium",
                    },
                    "& .MuiSwitch-root": {
                      width: "42px",
                      height: "20px",
                    },
                    "& .MuiSwitch-switchBase": {
                      margin: "2px",
                      transition:
                        "transform 0.3s ease, background-color 0.3s ease",
                    },

                    "& .MuiSwitch-switchBase.Mui-checked": {
                      WebkitTransform: "translateX(20px) !important", // Webkit-specific transform
                      MozTransform: "translateX(20px) !important", // Mozilla-specific transform
                      MsTransform: "translateX(20px) !important", // Microsoft-specific transform
                      transform: "translateX(20px) !important",
                    },
                    "& .MuiSwitch-thumb": {
                      boxSizing: "border-box",
                      width: "16px !important",
                      height: "16px !important",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: "#c4c7cc ",
                      borderRadius: "10px",
                      transition: "background-color 0.3s ease",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#2B2E33",
                    },
                  }}
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={allDay}
                      onChange={() => setAllDay(!allDay)}
                    />
                  }
                  label="All day"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <hr />
        <h4 className="repeat">Repeat</h4>
        <RadioGroup
          className="radiotxt"
          row
          name="repeat"
          value={formData.repeat}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        >
          <FormControlLabel value={"off"} control={<Radio />} label={"Off"} />
          <FormControlLabel
            value={"every1week"}
            control={<Radio />}
            label={"Every 1 Week"}
          />
          <FormControlLabel
            value={"every2week"}
            control={<Radio />}
            label={"Every 2 Weeks"}
          />
          <FormControlLabel
            value={"every3week"}
            control={<Radio />}
            label={"Every 3 Weeks"}
          />
          <FormControlLabel
            value={"monthly"}
            control={<Radio />}
            label={"Monthly"}
          />
        </RadioGroup>

        {formData.repeat !== "off" && (
          <Box>
            <Typography variant="h4" className="RepeatOn">
              Repeat On
            </Typography>
            <Box
              sx={{ display: "flex", gap: 0, flexWrap: "wrap", marginTop: 1 }}
              className="daywrp"
            >
              {days.map((day) => (
                <Typography
                  key={day}
                  onClick={() => handleDaySelect(day)}
                  sx={{
                    cursor: "pointer",
                    padding: "8px 16px",
                    borderRadius: "8px",
                    backgroundColor:
                      formData.repeatOn === day ? "#2B2E33" : "#ffffff",
                    color: formData.repeatOn === day ? "#fff" : "#000",
                    textAlign: "center",
                    transition: "background-color 0.3s",
                  }}
                >
                  {day}
                </Typography>
              ))}
            </Box>
          </Box>
        )}

        {formData.repeat !== "off" && (
          <Box sx={{ marginTop: 0 }} className="endinputform">
            <Typography variant="h4" className="endtitl">
              Ends
            </Typography>
            <div className="enddate">
              <RadioGroup
                row
                name="ends"
                value={formData.endsType}
                onChange={handleEndsChange}
                sx={{ marginBottom: 2 }}
              >
                <FormControlLabel
                  value="Never"
                  control={<Radio />}
                  label="Never"
                />
                <FormControlLabel value="On" control={<Radio />} label="On" />
              </RadioGroup>
              {formData.endsType === "On" && (
                <DatePicker
                  className="inputtxt open calender"
                  // value={formData.endDate}
                  name="endDate"
                  minDate={new Date()}
                  onChange={handleDateChangeEnds}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth margin="normal" />
                  )}
                />
                // </FormControl>
              )}
            </div>
          </Box>
        )}
        <hr></hr>
        <h4 style={{ marginBottom: "8px" }}>Appointment Note (optional)</h4>
        <TextField
          className="addtextarea"
          placeholder="Enter note here ..."
          name="appointmentNote"
          fullWidth
          multiline
          rows={3}
          margin="normal"
          shrink
          onChange={handleNotesChange}
        />
      </Box>
      <Box
        className="bttmbtn"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          marginTop: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderRadius: "30px",
            color: "#1976d2",
            borderColor: "#1976d2",
            padding: "8px 20px",
          }}
          onClick={() => setBlockTimeModal(false)}
        >
          Cancel
        </Button>
        <Button
          className="selectbtn"
          type="submit"
          onClick={handleSubmit}
          variant="outlined"
          color="primary"
          sx={{
            borderRadius: "30px",
            color: "#ffffff",

            padding: "8px 20px",
          }}
        >
          Book
        </Button>
      </Box>
    </LocalizationProvider>
  );
};

export default BlockTimeModal;
