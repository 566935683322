import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const siteId = "S00001";

export const addAppointment = createAsyncThunk(
  "api/appointments",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/appointments`,
        formData,
        {
          headers: {
            // Accept: "application/json",
            "Content-Type": "multipart/form-data"
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchAllAppointment = createAsyncThunk(
  "api/appointments/all",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/appointments`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteAppointment = createAsyncThunk(
  "appointments/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/appointments/deleteAppointment/${id}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );

      return { id, data: response.data };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


const reduxSlice = createSlice({
  name: "appointment",
  initialState: {
    all: {
      data: [],
      loading: false,
      error: null,
    },
    add: {
      data: {},
      loading: false,
      error: null,
    },
    delete: {
      loading: false,
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addAppointment.pending, (state) => {
        if (!state.add) state.add = {};
        state.add.loading = true;
        state.add.error = null;
      })
      .addCase(addAppointment.fulfilled, (state, action) => {
        state.add.loading = false;
        state.add.data = action.payload;
      })
      .addCase(addAppointment.rejected, (state, action) => {
        state.add.loading = false;
        state.add.error = action.payload;
      });

    builder
      .addCase(fetchAllAppointment.pending, (state) => {
        // if (!state.all) state.all = {};
        state.all.loading = true;
        state.all.error = null;
      })
      .addCase(fetchAllAppointment.fulfilled, (state, action) => {
        state.all.loading = false;
        state.all.data = action.payload;
      })
      .addCase(fetchAllAppointment.rejected, (state, action) => {
        state.all.loading = false;
        state.all.error = action.payload;
      });
      builder
      .addCase(deleteAppointment.pending, (state) => {
        state.delete.loading = true;
        state.delete.error = null;
      })
      .addCase(deleteAppointment.fulfilled, (state, action) => {
        state.delete.loading = false;
        console.log(state,"data")

        state.all.data.data = state?.all?.data.data?.filter(
          (item) => item.id !== action.payload.id
        );
      })
      .addCase(deleteAppointment.rejected, (state, action) => {
        state.delete.loading = false;
        state.delete.error = action.payload;
        console.error("Delete failed:", action.payload);
      });
  },
});

export const appointmentReducer = reduxSlice.reducer;
