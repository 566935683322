import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const siteId = "S00001";

export const addBlockTime = createAsyncThunk(
    "api/blocktime",
    async (formData, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/blocktime/create`,
                formData,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                }
            );

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const fetchAllBlockTime = createAsyncThunk(
    "api/blocktime/all",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/api/blocktime/all`,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);


const reduxSlice = createSlice({
    name: "blocktime",
    initialState: {
        all: {
            data: [],
            loading: false,
            error: null,
        },
        add: {
            data: {},
            loading: false,
            error: null,
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addBlockTime.pending, (state) => {
                if (!state.add) state.add = {};
                state.add.loading = true;
                state.add.error = null;
            })
            .addCase(addBlockTime.fulfilled, (state, action) => {
                state.add.loading = false;
                state.add.data = action.payload;
            })
            .addCase(addBlockTime.rejected, (state, action) => {
                state.add.loading = false;
                state.add.error = action.payload;
            });

        builder
            .addCase(fetchAllBlockTime.pending, (state) => {
                state.all.loading = true;
                state.all.error = null;
            })
            .addCase(fetchAllBlockTime.fulfilled, (state, action) => {
                state.all.loading = false;
                state.all.data = action.payload;
            })
            .addCase(fetchAllBlockTime.rejected, (state, action) => {
                state.all.loading = false;
                state.all.error = action.payload;
            });
    },
});

export const blockTimeReducer = reduxSlice.reducer;
