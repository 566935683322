import React from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  FormControl,
  InputAdornment,
  TextField,
  createStyles,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import profileImg from "../assets/images/image1.jpg";
import logo01 from "../assets/images/logo01.png";
import { toggleDrawerStatus } from "../store/drawer";

const useStyles = makeStyles(() => {
  return createStyles({
    search: {
      margin: "0",
    },
  });
});

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        // marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

export const MainAppBar = () => {
  const { search } = useStyles();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.menuExpand.all);
  const [showClearIcon, setShowClearIcon] = useState("");
  const isMobile = useMediaQuery("(max-width:992px)");
  const handleChange = (event) => {
    setShowClearIcon(event.target.value);
  };
  console.log(isMobile);
  const setDrawer = () => {
    dispatch(toggleDrawerStatus());
  };
  return (
    <>
      <AppBar
        position="fixed"
        open={true}
        className={`topBar ${data?.menuStatus === false ? "" : "reduceHeader"}`}
      >
        <Toolbar className="headerbar">
          {isMobile ? (
            <>
              <div className="topLeft">
                <img
                  src={logo01}
                  alt="logo"
                  className="img-fluid"
                  style={{ maxWidth: "82px" }}
                />
              </div>
              <div className="topRight">
                <div className="searchicon">
                  <SearchIcon />
                </div>
                <div className="menuicon">
                  <MenuIcon onClick={() => setDrawer()} />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="topLeft">
                <FormControl className={search} id="searchInputform">
                  <TextField
                    className="searchInput"
                    onChange={handleChange}
                    placeholder="Search"
                    value={showClearIcon}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </div>
              <div className="topRight">
                <SettingsIcon />
                <NotificationsNoneIcon />
                <img
                  className="userIcon"
                  src={profileImg}
                  width="50"
                  alt="userIocn"
                />
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};
