import React, { useEffect, useState } from "react";
import Link from "@material-ui/core/Link";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  TextField,
  MenuItem,
  FormControl,
  Select,
  Button,
  Typography,
  Box,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuList,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  Chip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { toast } from "react-toastify";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fetchCustomers } from "../store/customer";
import { fetchSpecialists } from "../store/specialist";
import { addAppointment } from "../store/appointment";
import loyalityIcon from "../assets/images/loyalityIcon.png";

import "./appointment.css";
import clipboardIcon from "../assets/images/clipboard.png";
import eyeoffIcon from "../assets/images/eyeoff.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import slashIcon from "../assets/images/slash.png";
import dollorIcon from "../assets/images/dollar.png";
import { Grid } from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import AddCustomer from "./addCustomer";
import SelectWithChips from "../components/SelectWithChips";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import oragdotIcon from "../assets/images/icon/03dot.png";
import FormLabel from "@mui/material/FormLabel";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import femaleIcon from "../assets/images/icon/ic_round-female.png";
import Switch from "@mui/material/Switch";
import CustomTimeline from "../components/CustomTimeline";
import dotButtonIcon from "../assets/images/dotButton.png";
import allergyIcon from "../assets/images/allergy.png";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// --
import dotIcon from "../assets/images/icon/02dot.png";
import userIcon from "../assets/images/userIcon.png";
import calendarIcon from "../assets/images/calendarIcon.png";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import dollarIcon from "../assets/images/icon/dollar.png";
const RebookDetails = (props) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    customer: "",
    services: "",
    date: null,
    startTime: null,
    bookedBy: "",
    bookingChannel: "",
    repeat: "off",
    repeatOn: null,
    ends: "Never",
    endDate: "",
    appointmentNote: "",
    attachFile: null,
  });
  const [searchVal, setSearchVal] = useState("");
  const [allDay, setAllDay] = useState(false);
  const [customerListState, setCustomerListState] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isOpenStartDate, setIsOpenStartDate] = useState(false);
  const [isOpenEndDate, setIsOpenEndDate] = useState(false);
  const [isOpenTimeStart, setIsOpenTimeStart] = useState(false);
  const [isOpenTimeEnd, setIsOpenTimeEnd] = useState(false);
  const [totalSummary, setTotalSummary] = useState({
    price: 0,
    duration: 0,
  });
  const [openServiceModel, setOpenServiceModel] = useState(false);
  const { data: customersList } = useSelector((state) => state.customers.all);
  const { data: specialistData } = useSelector(
    (state) => state.specialists.all
  );
  const handleNotesChange = (event) => {
    setFormData({ ...formData, description: event.target.value });
  };
  const {
    data: addAppointmentData = null,
    loading = false,
    error = null,
  } = useSelector((state) => state.appointment.add || {});
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const [value, setValue] = React.useState("1");

  useEffect(() => {
    dispatch(fetchCustomers({ searchVal }));
  }, [dispatch, searchVal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    const newFiles = files.map((file) => ({
      file,
      preview: file.type.startsWith("image/")
        ? URL.createObjectURL(file)
        : null, // Generate preview URL for images only
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleFileClick = (file) => {
    if (file.type.startsWith("image/")) {
      window.open(URL.createObjectURL(file), "_blank");
    } else if (file.type === "application/pdf") {
      window.open(URL.createObjectURL(file), "_blank");
    }
  };

  const handleDaySelect = (day) => {
    setFormData({ ...formData, repeatOn: day });
  };

  const handleEndsChange = (event) => {
    setFormData({
      ...formData,
      ends: event.target.value,
      endDate: event.target.value === "On" ? formData.endDate : "",
    });
  };

  const handleDateChangeEnds = (value) => {
    setFormData({ ...formData, endDate: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formPayload = new FormData();

    let checkValidation = true;
    if (!selectedCustomer?._id) {
      toast.error("Please select customer", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (!formData?.reason) {
      toast.error("Please select reason", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (!formData?.specialistId) {
      toast.error("Please select specialist", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (!formData?.startDate) {
      toast.error("Please select start date", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (!formData?.endDate) {
      toast.error("Please select end date", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (allDay && !formData?.startTime) {
      toast.error("Please select start time", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (allDay && !formData?.endTime) {
      toast.error("Please select end time", {
        toastId: "form-validation",
      });
      checkValidation = false;
    }
    if (formData?.repeat !== "off") {
      if (!formData?.repeatOn) {
        toast.error("Please select repeat on", {
          toastId: "form-validation",
        });
        checkValidation = false;
      }
      if (formData?.endsType == "On" && !formData?.ends) {
        toast.error("Please select ends date", {
          toastId: "form-validation",
        });
        checkValidation = false;
      }
    }

    for (let dt of selectedServices) {
      if (!dt?._id) {
        toast.error("Please select services", {
          toastId: "form-validation",
        });
        checkValidation = false;
        return 0;
      }
      if (!dt?.startTime) {
        toast.error("Please select start time", {
          toastId: "form-validation",
        });
        checkValidation = false;
        return 0;
      }
      if (!dt?.preferred_specialist) {
        toast.error("Please select preferred specialist", {
          toastId: "form-validation",
        });
        checkValidation = false;
        return 0;
      }
    }

    if (!formData?.bookedBy) {
      toast.error("Please select booked by", {
        toastId: "form-validation",
      });
      checkValidation = false;
      return 0;
    }
    if (!formData?.bookingChannel) {
      toast.error("Please select booking channel", {
        toastId: "form-validation",
      });
      checkValidation = false;
      return 0;
    }

    if (formData?.repeat != "off" && !formData?.repeatOn) {
      toast.error("Please select repeat on day", {
        toastId: "form-validation",
      });
      checkValidation = false;
      return 0;
    } else if (
      formData?.repeat != "off" &&
      formData?.ends == "On" &&
      !formData?.endDate
    ) {
      toast.error("Please select end date", {
        toastId: "form-validation",
      });
      checkValidation = false;
      return 0;
    }

    if (!formData?.date) {
      toast.error("Please select date of appointment", {
        toastId: "form-validation",
      });
      checkValidation = false;
      return 0;
    }

    if (checkValidation) {
      formPayload.append("customerId", selectedCustomer?._id);

      for (let i in selectedServices) {
        formPayload.append(
          `services[${i}][subcategoryId]`,
          selectedServices[i]?._id
        );
        formPayload.append(
          `services[${i}][startTime]`,
          selectedServices[i]?.startTime
        );
        formPayload.append(
          `services[${i}][specialistId]`,
          selectedServices[i]?.preferred_specialist
        );
      }
      formPayload.append("bookedBy", formData?.bookedBy);
      formPayload.append("bookingChannel", formData?.bookingChannel);
      formPayload.append("repeat", formData?.repeat);
      if (formData?.repeat !== "off") {
        if (formData?.repeatOn) {
          formPayload.append("repeatOn", formData?.repeatOn);
        }
        if (formData?.ends == "On") {
          formPayload.append("ends", "On Date");
          if (formData?.endDate) {
            formPayload.append(
              "endDate",
              moment(formData?.endDate).format("YYYY-MM-DD")
            );
          }
        }
      }
      formPayload.append("appointmentNotes", formData?.appointmentNote);
      formPayload.append("status", "notconfirmed");
      formPayload.append(
        "appointmentDate",
        moment(formData?.date).format("YYYY-MM-DD")
      );

      uploadedFiles.forEach((fileObj, index) => {
        formPayload.append("attachments", fileObj.file); // Use the same key for all files
      });

      const payloadObject = {};
      for (let pair of formPayload.entries()) {
        payloadObject[pair[0]] = pair[1];
      }
      try {
        const resultAction = await dispatch(
          addAppointment(formPayload)
        ).unwrap();
        if (resultAction.success) {
          dispatch(fetchSpecialists());
          toast.success(resultAction.message, { toastId: "api-success" });
          props.setRebook(false);
        } else {
          toast.error(resultAction.message, { toastId: "api-success" });
        }
      } catch (error) {
        toast.error(`Failed to create appointment: ${error}`, {
          toastId: "api-error",
        });
        console.error("API Error:", error);
      }
    }
  };

  const onClickServiceHandler = () => {
    setOpenServiceModel(true);
  };
  const addServiceCloseHandler = (data) => {
    setOpenServiceModel(data);
  };

  function handleCustomerSelect(obj) {
    setSearchVal(
      obj?.first_name + " " + obj?.last_name + " - " + obj?.phone_number_mobile
    );
    setSelectedCustomer(obj);
    setCustomerListState(false);
  }

  const handleEndTimeChange = (time) => {
    setFormData({ ...formData, endTime: moment(time).format("HH:mm") });
  };
  function handleCustomerClose() {
    setSearchVal("");
    setSelectedCustomer({});
    setCustomerListState(false);
  }

  const handleSeriveDelete = (rowData) => {
    let tempData = selectedServices.filter((dt) => dt._id != rowData._id);
    setSelectedServices([...tempData]);
    setTotalSummary((prev) => {
      return {
        price: prev.price - rowData.price,
        duration: prev.duration - rowData.duration,
      };
    });
  };

  const handleSpecialistChange = (event, index) => {
    let tempObj = JSON.parse(JSON.stringify(selectedServices));
    tempObj[index]["preferred_specialist"] = event.target.value;
    setSelectedServices([...tempObj]);
  };

  const handleStartTimeChange = (event, index) => {
    let tempObj = JSON.parse(JSON.stringify(selectedServices));
    tempObj[index]["startTime"] = event.target.value;
    setSelectedServices([...tempObj]);
  };
  // Utility function to shorten file name
  function shortenFileName(fileName) {
    const maxLength = 10; // Maximum length for the file name
    const dotIndex = fileName.lastIndexOf(".");
    const extension = dotIndex !== -1 ? fileName.slice(dotIndex) : "";
    const baseName = dotIndex !== -1 ? fileName.slice(0, dotIndex) : fileName;

    if (baseName.length > maxLength) {
      return `${baseName.slice(0, maxLength)}...${extension}`;
    }
    return fileName;
  }

  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [addCustomerToggle, setAddCustomerToggle] = useState(false);
  const openCreatCustomerModal = () => {
    setAddCustomerToggle(true);
    setOpenCustomerModal(true);
    //props.setAddAppointmentToggle(false)
  };

  // select with chips
  const [status, setStatus] = useState("Confirmed");
  const handleChangeSelect = (event) => {
    setStatus(event.target.value);
  };
  const handleEndDateChange = (date) => {
    setFormData({ ...formData, endDate: moment(date).format("YYYY-MM-DD") });
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleSpecilistSelect = (event) => {
    setFormData({ ...formData, specialistId: event.target.value });
  };
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#65C466",
          opacity: 1,
          border: 0,
          ...theme.applyStyles("dark", {
            backgroundColor: "#2ECA45",
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        ...theme.applyStyles("dark", {
          color: theme.palette.grey[600],
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
        ...theme.applyStyles("dark", {
          opacity: 0.3,
        }),
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      ...theme.applyStyles("dark", {
        backgroundColor: "#39393D",
      }),
    },
  }));
  // timeLine
  const events = [
    {
      icon: (
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.8333 14.8327C13.8333 15.2927 13.4592 15.666 13 15.666H3C2.54083 15.666 2.16667 15.2927 2.16667 14.8327V5.66602C2.16667 5.20602 2.54083 4.83268 3 4.83268V5.66602C3 6.58518 3.7475 7.33268 4.66667 7.33268H11.3333C12.2525 7.33268 13 6.58518 13 5.66602V4.83268C13.4592 4.83268 13.8333 5.20602 13.8333 5.66602V14.8327ZM4.66667 2.33268L11.3333 2.33518V3.16602V5.66602H4.66667V3.16602V2.33268ZM13 3.16602V2.33268C13 1.41352 12.2525 0.666016 11.3333 0.666016H4.66667C3.7475 0.666016 3 1.41352 3 2.33268V3.16602C1.62167 3.16602 0.5 4.28768 0.5 5.66602V14.8327C0.5 16.211 1.62167 17.3327 3 17.3327H13C14.3783 17.3327 15.5 16.211 15.5 14.8327V5.66602C15.5 4.28768 14.3783 3.16602 13 3.16602Z"
            fill="#898E99"
          />
        </svg>
      ),
      title: "The appointment will take place.",
      timestamp: "In 12h",
      color: "primary",
    },
    {
      icon: (
        <svg
          width="14"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.0004 10.666H4.5004C4.0404 10.666 3.66707 10.2927 3.66707 9.83268C3.66707 9.37268 4.0404 8.99935 4.5004 8.99935H7.0004C7.4604 8.99935 7.83373 9.37268 7.83373 9.83268C7.83373 10.2927 7.4604 10.666 7.0004 10.666ZM3.66707 13.166C3.66707 12.706 4.0404 12.3327 4.5004 12.3327H9.5004C9.96123 12.3327 10.3337 12.706 10.3337 13.166C10.3337 13.626 9.96123 13.9993 9.5004 13.9993H4.5004C4.0404 13.9993 3.66707 13.626 3.66707 13.166ZM11.5373 15.666H2.46315C2.20815 15.666 2.00065 15.4793 2.00065 15.2493V2.74935C2.00065 2.51935 2.20815 2.33268 2.46315 2.33268H7.00065V4.95768C7.00065 6.26768 8.01482 7.33268 9.26232 7.33268H12.0007V15.2493C12.0007 15.4793 11.7932 15.666 11.5373 15.666ZM8.66732 3.14768L10.9523 5.66602H9.26232C8.93398 5.66602 8.66732 5.34852 8.66732 4.95768V3.14768ZM13.4506 5.93935L8.91398 0.939349C8.75565 0.765182 8.53232 0.666016 8.29648 0.666016H2.46315C1.28898 0.666016 0.333984 1.60102 0.333984 2.74935V15.2493C0.333984 16.3977 1.28898 17.3327 2.46315 17.3327H11.5373C12.7115 17.3327 13.6673 16.3977 13.6673 15.2493V6.49935C13.6673 6.29185 13.5898 6.09268 13.4506 5.93935Z"
            fill="#898E99"
          />
        </svg>
      ),
      title: "Maria Doe added a note",
      description:
        "Angela did not turn up for her 3PM appointment and left without notifying us about it.",
      timestamp: "Apr 19, 2024, 3PM",
      color: "secondary",
    },
    {
      icon: (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.833 6.16732H7.83301V1.16732C7.83301 0.706484 7.45967 0.333984 6.99967 0.333984C6.53967 0.333984 6.16634 0.706484 6.16634 1.16732V6.16732H1.16634C0.706341 6.16732 0.333008 6.53982 0.333008 7.00065C0.333008 7.46148 0.706341 7.83398 1.16634 7.83398H6.16634V12.834C6.16634 13.2948 6.53967 13.6673 6.99967 13.6673C7.45967 13.6673 7.83301 13.2948 7.83301 12.834V7.83398H12.833C13.293 7.83398 13.6663 7.46148 13.6663 7.00065C13.6663 6.53982 13.293 6.16732 12.833 6.16732Z"
            fill="#898E99"
          />
        </svg>
      ),
      title: "Appointment has been created by Maria Doe",
      timestamp: "Apr 19, 2024, 11AM",
      color: "success",
    },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} className="kk">
      <Box
        className="pd19"
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          borderBottom: "1px solid #E1E3E6",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            variant="span"
            className="Heading_pop"
            sx={{ borderBottom: "0px solid #E1E3E6", padding: "0px" }}
          >
            {" "}
            Rebook Appointment
          </Typography>
        </Box>
        <Box
          className="selctMenu"
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            gap: "8px",
          }}
        ></Box>
      </Box>
      <span className="crosssicon" onClick={() => props.setRebook(false)}>
        <ClearIcon />
      </span>
      <Box
        className="AppointPop rebooking"
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          // maxWidth: "500px",
          margin: "0 auto",
          padding: "20px",
          boxShadow: 3,
          borderRadius: "12px",
          bgcolor: "#ffffff",
        }}
      >
        <Box>
          <h4>Customer</h4>
          <TextField
            className="inputfield"
            name="customer"
            value={searchVal}
            fullWidth
            margin="normal"
            autoComplete="off"
            placeholder="Name, Email or Phone Number"
            onChange={(e) => {
              setSearchVal(e.target.value);
              setCustomerListState(true);
            }}
            InputProps={{
              startAdornment: (
                <IconButton sx={{ padding: "10px" }}>
                  <SearchIcon />
                </IconButton>
              ),
              endAdornment: (
                <IconButton sx={{ padding: "10px" }}>
                  <CloseIcon onClick={handleCustomerClose} />
                </IconButton>
              ),
            }}
          />
        </Box>
        <div className="customer-card custcustomer-card">
          <div className="card-header">
            <div className="card-header-left ">
              <h2>{`${selectedCustomer.first_name} ${selectedCustomer.last_name}`}</h2>
              <span className="newbg">New</span>
            </div>
            <div className="card-header-left card-header-rit">
              <span className="loyalty-tier loyalty-inner">
                Loyalty Points:{" "}
                <span className="low">
                  {selectedCustomer.loyaltyPoints || 343}
                </span>
              </span>
              <span className="loyalty-tier">
                Loyalty Tier:
                <span className="low">
                  {selectedCustomer.loyaltyTier || "Low"}
                </span>
              </span>
            </div>
          </div>
          <p className="phone-email">
            <span>{selectedCustomer.phone_number_mobile}</span> ·{" "}
            <span>{selectedCustomer.email}</span>
          </p>
          <div className="card-actions">
            <button className="btn smallspan">Notes (2)</button>
            <button className="btn smallspan">History (0)</button>
            <button className="btn smallspan">Check Out</button>
            <button className="btn smallspan">Edit</button>
          </div>
          <div className="card-actions bottom-card-actions">
            <div className="bottom-card-actions-lft">
              <p>
                <img src={clipboardIcon} alt="Clipboard Icon" /> Total Visits
                <span>{selectedCustomer.totalVisits || 0}</span>
              </p>
              <p>
                <img src={eyeoffIcon} alt="Eye Off Icon" />
                No Shows<span>{selectedCustomer.noShows || 0}</span>
              </p>
              <p>
                <img src={slashIcon} alt="Slash Icon" />
                Cancellations <span>{selectedCustomer.cancellations || 0}</span>
              </p>
            </div>
            <div className="bottom-card-actions-rit">
              <p>
                <img src={dollorIcon} alt="Dollar Icon" />
                Deposit<span>${selectedCustomer.deposit}</span>
              </p>
            </div>
          </div>
        </div>
        {/* tab section */}
        <hr />
        <FormControl fullWidth margin="normal"   
        style={{marginBottom: "16px"
                        }}>
          <h4 style={{ marginBottom: "8px" }}>Service</h4>
          <div className="bookdropswn">
            <select
              className="inputtxt"
              name="bookedBy"
              value={formData.bookedBy}
              onChange={handleChange}
            >
              <option value="selected"><span className="dots"></span> BIAB with shellac & removal</option>
              <option value="BIAB with shellac & removal">
                BIAB with shellac & removal
              </option>
            </select>
          </div>
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <h4 style={{ marginBottom: "8px" }}>Specialist</h4>
              <div className="bookdropswn">
                <select
                  className="inputtxt"
                  name="bookedBy"
                  value={formData.bookedBy}
                  onChange={handleChange}
                >
                  <option value="Abril Lewis">Abril Lewis</option>
                  <option value="Abril Lewis">Abril Lewis</option>
                </select>
              </div>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} className="Preffered"> 
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              className="heartIcon"
            >
              <FormControlLabel
                control={<Checkbox />}
                label="Preffered specialist"
              />
              <FavoriteBorderIcon />
            </FormGroup>
          </Grid>
        </Grid>
        <hr></hr>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" className="inputtxt custominputtext">
              Date
            </Typography>
            <DatePicker
              className="rounded-pill"
              sx={{
                "& .MuiInputAdornment-positionEnd": {
                  display: "none",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px  !important",
                  borderRadius: "20px",
                },
                "& .MuiInputBase-input": {
                  padding: "9px 16px !important",
                },
              }}
              minDate={new Date()}
              open={isOpenStartDate}
              onOpen={() => setIsOpenStartDate(true)}
              onClose={() => setIsOpenStartDate(false)}
              onChange={handleDateChange}
              // slots={{ openPickerIcon: null }} // Removes the default calendar icon
              slotProps={{
                textField: {
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={() => setIsOpenStartDate(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          width="12"
                          height="15"
                          viewBox="0 0 12 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.66667 10.1666C2.66667 9.79992 2.96667 9.49992 3.33333 9.49992C3.7 9.49992 4 9.79992 4 10.1666C4 10.5333 3.7 10.8333 3.33333 10.8333C2.96667 10.8333 2.66667 10.5333 2.66667 10.1666ZM6 9.49992H8.66667C9.03333 9.49992 9.33333 9.79992 9.33333 10.1666C9.33333 10.5333 9.03333 10.8333 8.66667 10.8333H6C5.63333 10.8333 5.33333 10.5333 5.33333 10.1666C5.33333 9.79992 5.63333 9.49992 6 9.49992ZM10 12.8333H2C1.63267 12.8333 1.33333 12.5339 1.33333 12.1666V8.16659H10.6667V12.1666C10.6667 12.5339 10.3673 12.8333 10 12.8333ZM2 3.49992H2.66667V4.16659C2.66667 4.53325 2.96667 4.83325 3.33333 4.83325C3.7 4.83325 4 4.53325 4 4.16659V3.49992H8V4.16659C8 4.53325 8.3 4.83325 8.66667 4.83325C9.03333 4.83325 9.33333 4.53325 9.33333 4.16659V3.49992H10C10.3673 3.49992 10.6667 3.79925 10.6667 4.16659V6.83325H1.33333V4.16659C1.33333 3.79925 1.63267 3.49992 2 3.49992ZM10 2.16659H9.33333V1.49992C9.33333 1.13325 9.03333 0.833252 8.66667 0.833252C8.3 0.833252 8 1.13325 8 1.49992V2.16659H4V1.49992C4 1.13325 3.7 0.833252 3.33333 0.833252C2.96667 0.833252 2.66667 1.13325 2.66667 1.49992V2.16659H2C0.897333 2.16659 0 3.06392 0 4.16659V12.1666C0 13.2693 0.897333 14.1666 2 14.1666H10C11.1027 14.1666 12 13.2693 12 12.1666V4.16659C12 3.06392 11.1027 2.16659 10 2.16659Z"
                            fill="#1D2023"
                          />
                        </svg>
                      </InputAdornment>
                    ),
                  },
                },
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} spacing={2}>
            <Typography variant="h4" className="inputtxt custominputtext">
              Time
            </Typography>
            <TimePicker
              className="rounded-pill"
              sx={{
                "& .MuiInputAdornment-positionEnd": {
                  display: "none",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px  !important",
                  borderRadius: "20px",
                },
                "& .MuiInputBase-input": {
                  padding: "9px 16px !important",
                },
              }}
              open={isOpenTimeStart}
              onOpen={() => setIsOpenTimeStart(true)}
              onClose={() => setIsOpenTimeStart(false)}
              onChange={handleStartTimeChange}
              views={["hours", "minutes"]}
              slotProps={{
                textField: {
                  fullWidth: true,
                  InputProps: {
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={() => setIsOpenTimeStart(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <AccessTimeIcon />
                      </InputAdornment>
                    ),
                  },
                },
              }}
            />
          </Grid>
     
        </Grid>
        <Grid container spacing={2} className="Tax">
        <Grid item xs={12} sm={6} >
            <Typography variant="h4" className="inputtxt custominputtext ">
              Price (with Tax)
            </Typography>
            <TextField
              className="inputfield"
              name="customer"
              value={searchVal}
              fullWidth
              margin="normal"
              autoComplete="off"
              placeholder="150.00"
              onChange={(e) => {
                setSearchVal(e.target.value);
                setCustomerListState(true);
              }}
            />
            <LocalOfferOutlinedIcon/>
          </Grid>
          <Grid item xs={12} sm={6} className="taxwatch">
            <Typography variant="h4" className="inputtxt custominputtext">
              Duration
            </Typography>
            <TextField
              className="inputfield "
              name="customer"
              value={searchVal}
              fullWidth
              margin="normal"
              autoComplete="off"
              placeholder="Select the time"
              onChange={(e) => {
                setSearchVal(e.target.value);
                setCustomerListState(true);
              }}
            />
            <TimerOutlinedIcon />
          </Grid>
        </Grid>
        <hr></hr>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
          <h4 style={{ marginBottom: "8px" }}>Booked by</h4>
              <div className="bookdropswn">
                <select
                  className="inputtxt"
                  name="bookedBy"
                  value={formData.bookedBy}
                  onChange={handleChange}
                >
                  <option value="Select manager">Select manager</option>
                  <option value="Select manager">Select manager</option>
                </select>
              </div>
          </Grid>
          <Grid item xs={12} sm={6}>
          <h4 style={{ marginBottom: "8px" }}>Booking channel</h4>
              <div className="bookdropswn">
                <select
                  className="inputtxt"
                  name="bookedBy"
                  value={formData.bookedBy}
                  onChange={handleChange}
                >
                  <option value=" a booking channel">Select a booking channel</option>
                  <option value=" a booking channel">Select a booking channel</option>
                </select>
              </div>
          </Grid>
        </Grid>
        <hr></hr>
        <Grid container spacing={2}>
          <Grid items xs={12} sm={12}>
            <h4 className="repeat">Repeat</h4>
            <RadioGroup
              className="radiotxt"
              row
              name="repeat"
              value={formData.repeat}
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
            >
              <FormControlLabel
                value={"off"}
                control={<Radio />}
                label={"Off"}
              />
              <FormControlLabel
                value={"every1week"}
                control={<Radio />}
                label={"Every 1 Week"}
              />
              <FormControlLabel
                value={"every2week"}
                control={<Radio />}
                label={"Every 2 Weeks"}
              />
              <FormControlLabel
                value={"every3week"}
                control={<Radio />}
                label={"Every 3 Weeks"}
              />
              <FormControlLabel
                value={"monthly"}
                control={<Radio />}
                label={"Monthly"}
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2}>
          <Grid items xs={12} sm={12}>
            <h4 style={{ marginBottom: "8px" }}>Appointment Note (optional)</h4>

            <TextField
              name="appointmentNote"
              value={formData.appointmentNote}
              onChange={handleChange}
              className="addtextarea"
              placeholder="Enter note here ..."
              fullWidth
              multiline
              rows={3}
              margin="normal"
              shrink
            />
            <Box className="attchdiv">
              <Button className="attchfile" component="label">
                <AttachFileIcon sx={{ marginRight: "8px" }} />
                Attach File
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={handleFileUpload}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        className="bttmbtn"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          marginTop: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderRadius: "30px",
            color: "#1976d2",
            borderColor: "#1976d2",
            padding: "8px 20px",
          }}
          onClick={() => props.setRebook(false)}
        >
          Cancel
        </Button>
        <Button
          className="selectbtn"
          type="submit"
          onClick={handleSubmit}
          variant="outlined"
          color="primary"
          sx={{
            borderRadius: "30px",
            color: "#ffffff",

            padding: "8px 20px",
          }}
        >
          Book
        </Button>
      </Box>
      {openCreatCustomerModal && (
        <>
          <AddCustomer
            addCustomerToogle={addCustomerToggle}
            setAddCustomerToggle={setAddCustomerToggle}
          />
        </>
      )}
    </LocalizationProvider>
  );
};

export default RebookDetails;
