import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import { Grid } from "@material-ui/core";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import loyalityIcon from "../assets/images/loyalityIcon.png";

const LoyaltyPointModal = ({ open, handleClose }) => {
  const [selectedNoteType, setSelectedNoteType] = useState("Formula");

  const handleNoteTypeChange = (event) => {
    setSelectedNoteType(event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: 1601,
      }}
      className="notePopup loyaltyPoint"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 528,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          sx={{ p: 2, pb: 0, position: "relative" }}
        >
          Loyalty Points
          <span className="crosssicon">
            <CloseIcon />
          </span>
        </Typography>

        <Box className="notePopupBox" sx={{ p: 3 }}>
          <Box>
            <TextField
              className="inputfield"
              name="customer"
              fullWidth
              margin="normal"
              autoComplete="off"
              placeholder="345"
            />
            <img src={loyalityIcon} className="iconimg"/>
          </Box>
        </Box>

        {/* Actions */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 24px",
            borderTop: "1px solid #F0F1F2",
            gap: "8px",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              flexGrow: "1",
              height: "48px",
              borderRadius: "22px!important",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{
              flexGrow: "1",
              height: "48px",
              borderRadius: "22px!important",
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LoyaltyPointModal;

