import { combineReducers } from "@reduxjs/toolkit";
import { specialistsReducer } from "./specialist";
import { menuExpandReducer } from "./menuExpand";
import { customersReducer } from "./customer";
import { servicesReducer } from "./services";
import { appointmentReducer } from "./appointment";
import { blockTimeReducer } from "./blocktime";
import { drawerExpandReducer } from "./drawer";
import { appointmentDetailReducer } from "./appointmentDetail";
import { getAppointmentDetailReducer } from "./getAppointmentById";
import { getSingleCustomerReducer } from "./getCustmerById";
import { getAppointmentNotesReducer } from "./appointmentNotes";
import { getAppointmentTimeLineReducer } from "./appointmentTimeLine";
import { getCustomerAppointmentReducer } from "./getCustomerAppointment";
import { getCustomerNotesReducer } from "./getCustomerNotes";
import { getCustomerTimelineReducer } from "./getCustomerTimeline";


const rootReducer = combineReducers({
    specialists: specialistsReducer,
    menuExpand: menuExpandReducer,
    customers: customersReducer,
    services: servicesReducer,
    appointment: appointmentReducer,
    blockTime: blockTimeReducer,
    drawerExpand:drawerExpandReducer,
    appointmentDetailReducer:appointmentDetailReducer,
    getAppointmentDetailReducer:getAppointmentDetailReducer,
    getSingleCustomerReducer:getSingleCustomerReducer,
    getAppointmentNotesReducer:getAppointmentNotesReducer,
    getAppointmentTimeLineReducer:getAppointmentTimeLineReducer,
    getCustomerAppointmentReducer:getCustomerAppointmentReducer,
    getCustomerNotesReducer:getCustomerNotesReducer,
    getCustomerTimelineReducer:getCustomerTimelineReducer
});
export default rootReducer;