import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceRecord } from "../store/services";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import shoppingCart from "../assets/images/icon/shoppingCart.png";
import timer from "../assets/images/icon/timer.png";
import pricetag from "../assets/images/icon/pricetag.png";
import pink from "../assets/images/icon/01dot.png";
import green from "../assets/images/icon/02dot.png";
import orange from "../assets/images/icon/03dot.png";
import CheckMarkSVGIcon from "../assets/images/icon/checkmark.svg";

import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import "./addServices.css";
import { fromJSON } from "postcss";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddServices = (props) => {
  const dispatch = useDispatch();
  const [searchVal, setSearchVal] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedService, setSelectedService] = useState([]);

  const { data: serviceList } = useSelector((state) => state.services.all);

  useEffect(() => {
    dispatch(fetchServiceRecord({ searchVal }));
  }, [dispatch, searchVal]);

  useEffect(() => {
    setSelectedService([...props.selectedServices]);
    setTotalPrice(props.totalSummary.price);
  }, [props.selectedServices]);

  const handleClose = () => {
    props.addServiceCloseHandler(false);
    setSelectedService([...props.selectedServices]);
    setTotalPrice(props.totalSummary.price);
  };

  function handleAddService(subCat) {
    setSelectedService([...selectedService, subCat]);
    setTotalPrice((prev) => prev + subCat?.price);
  }

  function handleRemoveService(subCat) {
    let tempDt = selectedService.filter((dt) => dt?._id != subCat._id);
    setSelectedService([...tempDt]);
    setTotalPrice((prev) => prev - subCat?.price);
  }

  function handleAddServiceInTable() {
    props.setSelectedServices([...selectedService]);
    props.addServiceCloseHandler(false);
    let tempObj = {
      price: 0,
      duration: 0,
    };
    for (let i of selectedService) {
      tempObj["price"] += i.price;
      tempObj["duration"] += i.duration;
    }
    props.setTotalSummary({ ...tempObj });
  }

  return (
    <>
      <Modal
        className="custom-modal"
        open={props.openServiceModel}
        // onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style} className="custom-modal-inner">
          <div className="modelHeader">
            <h2 id="parent-modal-title" className="mb-0 d-inline-block lh-1">
              Select Services
            </h2>
            <CloseIcon onClick={handleClose} />
          </div>
          <div className="serchService">
            <TextField
              name="customer"
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
              fullWidth
              margin="normal"
              placeholder="Search"
              sx={{
                border: "1px solid #E1E3E6",
                borderRadius: "30px",
                backgroundColor: "#f5f5f500",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "30px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <IconButton sx={{ padding: "10px" }}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </div>

          <div className="scrollServiceContent">
            <div className="service_inner">
              {serviceList?.length
                ? serviceList.map((serviceDt) => {
                    return (
                      <Accordion
                        key={serviceDt._id}
                        className="custom-accordion"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {serviceDt.category_name} (
                          {serviceDt?.subcategories?.length || 0})
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="panel_content">
                            {serviceDt?.subcategories?.length
                              ? serviceDt.subcategories.map((subCatDt) => {
                                  return (
                                    <div
                                      key={subCatDt._id}
                                      className="panel_content_rw"
                                    >
                                      <div className="_content_rw_lft">
                                        <div className="">
                                          <p>
                                            <span
                                              className="sub-category-dot"
                                              style={{
                                                background:
                                                  subCatDt?.appointment_colour,
                                              }}
                                            ></span>
                                            {subCatDt?.service_name}
                                          </p>
                                        </div>
                                        <div className="service_price">
                                          <span>
                                            <img src={pricetag} />$
                                            {subCatDt?.price}
                                          </span>
                                          <span>
                                            <img src={timer} />
                                            {subCatDt?.duration || 0}m
                                          </span>
                                        </div>
                                      </div>
                                      {selectedService.find(
                                        (dt) => dt?._id == subCatDt._id
                                      ) ? (
                                        <button
                                          onClick={() =>
                                            handleRemoveService(subCatDt)
                                          }
                                          className="_content_rw"
                                        >
                                          <div className="_content_rw_rit added">
                                            <span className="">
                                              <img src={CheckMarkSVGIcon} />
                                            </span>
                                            <span style={{ color: "white" }}>
                                              Added
                                            </span>
                                          </div>
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() =>
                                            handleAddService(subCatDt)
                                          }
                                          className="_content_rw"
                                        >
                                          <div className="_content_rw_rit">
                                            <span>
                                              <img src={shoppingCart} />
                                            </span>
                                            Add
                                          </div>
                                        </button>
                                      )}
                                    </div>
                                  );
                                })
                              : ""}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                : ""}
            </div>
          </div>

          <Box
            className="popupBottomBtn "
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginTop: 2,
            }}
          >
            <div className="bottomAmount">
              <div className="service_price">
                <span>Total:</span>
                <span>${totalPrice}</span>
              </div>
            </div>
            <div
              className="bttmbtn addbtn"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: 2,
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "30px",
                  color: "#1976d2",
                  borderColor: "#1976d2",
                  padding: "8px 20px",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="selectbtn"
                type="submit"
                variant="outlined"
                color="primary"
                sx={{
                  borderRadius: "30px",
                  color: "#ffffff",

                  padding: "8px 20px",
                }}
                onClick={handleAddServiceInTable}
              >
                Add({selectedService?.length || 0})
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddServices;
