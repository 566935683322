import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import MiniDrawer from '../layout/LeftSideNavbar';
import { MainAppBar } from '../layout/AppBar';

const ProtectedLayout = () => {
  const navigate = useNavigate();
  const { data: menuExpandData } = useSelector((state) => state.menuExpand.all);
  const isLoggedIn = true; //useSelector((state) => state?.login?.result?.email);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  // Display a fallback UI while checking authentication
  if (!isLoggedIn) {
    return <div>Loading...</div>;
  }

  console.log(menuExpandData,"menuExpandData");
  return (
    <div className={`${menuExpandData?.menuStatus ===false ? "outerBox fullWidth" : "outerBox reduceWidth"}`}>
      <MainAppBar />
      <MiniDrawer />
      <Outlet />
    </div>
  );
};

export default ProtectedLayout;
