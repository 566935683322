import React, { useEffect, useState } from "react";
import Link from "@material-ui/core/Link";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  MenuItem,
  Select,
  Button,
  Typography,
  Box,
  Chip,
  Menu,
} from "@mui/material";
import { ListItemIcon } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fetchCustomers } from "../store/customer";
import { fetchSpecialists } from "../store/specialist";
import { addAppointment } from "../store/appointment";
import "./appointment.css";
import clipboardIcon from "../assets/images/clipboard.png";
import eyeoffIcon from "../assets/images/eyeoff.png";
import slashIcon from "../assets/images/slash.png";
import dollorIcon from "../assets/images/dollar.png";
import { Grid } from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import AddCustomer from "./addCustomer";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import dotButtonIcon from "../assets/images/dotButton.png";
import allergyIcon from "../assets/images/allergy.png";
import { appointmentDetail } from "../store/appointmentDetail";
import { getAppointmentDetail } from "../store/getAppointmentById";
import { getCustomer, getSingleCustomer } from "../store/getCustmerById";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Paper from "@mui/material/Paper";
import { getAppointmentNotes } from "../store/appointmentNotes";
import {
  getAppointmentTimeLine,
  getAppointmentTimeLineReducer,
} from "../store/appointmentTimeLine";

const BookAppointmentDetail = (props) => {
  const dispatch = useDispatch();
  const allData = useSelector(
    (state) => state?.getAppointmentDetailReducer.all
  );
  const [appointments, setAppointments] = useState(allData?.data?.data);
  const getData = useSelector(
    (state) => state?.getSingleCustomerReducer?.all?.data
  );
  const getNotes = useSelector(
    (state) => state?.getAppointmentTimeLineReducer?.all?.data?.data
  );
  const [value, setValue] = React.useState("1");
  const [status, setStatus] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [addCustomerToggle, setAddCustomerToggle] = useState(false);
  

  // use info modal
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   dispatch(
  //     appointmentDetail({
  //       id: props.selectedEventId,
  //       status: status,
  //       updatedBy: "salon admin",
  //     })
  //   );
  // }, [status]);

  useEffect(() => {
    if (props.selectedEventId) {
      dispatch(getAppointmentDetail(props.selectedEventId));
    }
  }, [props.selectedEventId, status]);

  useEffect(() => {
    if (appointments) {
      dispatch(getSingleCustomer(appointments[0]?.CustomerId));
      setStatus(appointments[0]?.Status);
    }

  }, [appointments]);

  useEffect(() => {
    if (props.selectedEventId) {
      dispatch(getAppointmentTimeLine(props.selectedEventId));
    }
  }, [props.selectedEventId]);

  const openCreatCustomerModal = () => {
    setAddCustomerToggle(true);
    setOpenCustomerModal(true);
    //props.setAddAppointmentToggle(false)
  };

  const handleChangeSelect = async(event) => {
    setStatus(event.target.value);
    const response =await dispatch(
      appointmentDetail({
        id: props.selectedEventId,
        status: event.target.value,
        updatedBy: "salon admin",
      })
    );
    if(response){
      dispatch(getAppointmentDetail(props.selectedEventId));
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const events = [
    {
      icon: (
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.8333 14.8327C13.8333 15.2927 13.4592 15.666 13 15.666H3C2.54083 15.666 2.16667 15.2927 2.16667 14.8327V5.66602C2.16667 5.20602 2.54083 4.83268 3 4.83268V5.66602C3 6.58518 3.7475 7.33268 4.66667 7.33268H11.3333C12.2525 7.33268 13 6.58518 13 5.66602V4.83268C13.4592 4.83268 13.8333 5.20602 13.8333 5.66602V14.8327ZM4.66667 2.33268L11.3333 2.33518V3.16602V5.66602H4.66667V3.16602V2.33268ZM13 3.16602V2.33268C13 1.41352 12.2525 0.666016 11.3333 0.666016H4.66667C3.7475 0.666016 3 1.41352 3 2.33268V3.16602C1.62167 3.16602 0.5 4.28768 0.5 5.66602V14.8327C0.5 16.211 1.62167 17.3327 3 17.3327H13C14.3783 17.3327 15.5 16.211 15.5 14.8327V5.66602C15.5 4.28768 14.3783 3.16602 13 3.16602Z"
            fill="#898E99"
          />
        </svg>
      ),
      title: "The appointment will take place.",
      timestamp: "In 12h",
      color: "primary",
    },
    {
      icon: (
        <svg
          width="14"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.0004 10.666H4.5004C4.0404 10.666 3.66707 10.2927 3.66707 9.83268C3.66707 9.37268 4.0404 8.99935 4.5004 8.99935H7.0004C7.4604 8.99935 7.83373 9.37268 7.83373 9.83268C7.83373 10.2927 7.4604 10.666 7.0004 10.666ZM3.66707 13.166C3.66707 12.706 4.0404 12.3327 4.5004 12.3327H9.5004C9.96123 12.3327 10.3337 12.706 10.3337 13.166C10.3337 13.626 9.96123 13.9993 9.5004 13.9993H4.5004C4.0404 13.9993 3.66707 13.626 3.66707 13.166ZM11.5373 15.666H2.46315C2.20815 15.666 2.00065 15.4793 2.00065 15.2493V2.74935C2.00065 2.51935 2.20815 2.33268 2.46315 2.33268H7.00065V4.95768C7.00065 6.26768 8.01482 7.33268 9.26232 7.33268H12.0007V15.2493C12.0007 15.4793 11.7932 15.666 11.5373 15.666ZM8.66732 3.14768L10.9523 5.66602H9.26232C8.93398 5.66602 8.66732 5.34852 8.66732 4.95768V3.14768ZM13.4506 5.93935L8.91398 0.939349C8.75565 0.765182 8.53232 0.666016 8.29648 0.666016H2.46315C1.28898 0.666016 0.333984 1.60102 0.333984 2.74935V15.2493C0.333984 16.3977 1.28898 17.3327 2.46315 17.3327H11.5373C12.7115 17.3327 13.6673 16.3977 13.6673 15.2493V6.49935C13.6673 6.29185 13.5898 6.09268 13.4506 5.93935Z"
            fill="#898E99"
          />
        </svg>
      ),
      title: "Maria Doe added a note",
      description:
        "Angela did not turn up for her 3PM appointment and left without notifying us about it.",
      timestamp: "Apr 19, 2024, 3PM",
      color: "secondary",
    },
    {
      icon: (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.833 6.16732H7.83301V1.16732C7.83301 0.706484 7.45967 0.333984 6.99967 0.333984C6.53967 0.333984 6.16634 0.706484 6.16634 1.16732V6.16732H1.16634C0.706341 6.16732 0.333008 6.53982 0.333008 7.00065C0.333008 7.46148 0.706341 7.83398 1.16634 7.83398H6.16634V12.834C6.16634 13.2948 6.53967 13.6673 6.99967 13.6673C7.45967 13.6673 7.83301 13.2948 7.83301 12.834V7.83398H12.833C13.293 7.83398 13.6663 7.46148 13.6663 7.00065C13.6663 6.53982 13.293 6.16732 12.833 6.16732Z"
            fill="#898E99"
          />
        </svg>
      ),
      title: "Appointment has been created by Maria Doe",
      timestamp: "Apr 19, 2024, 11AM",
      color: "success",
    },
  ];

  const colors = {
    notconfirmed: "#C27803",
    confirmed: "#0E9F6E",
    "no-show": "#6B7280",
    show: "#0694A2",
    cancelled: "#E02424",
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      weekday: "short", // Short weekday name (e.g., Fri)
      day: "2-digit", // Day of the month (e.g., 26)
      month: "short", // Short month name (e.g., Jul)
      year: "numeric", // Full year (e.g., 2024)
    }).format(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} className="kk">
      <Box
        className="pd19 Heading_popOuter_withCloseIocn"
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          borderBottom: "1px solid #E1E3E6",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            variant="span"
            className="Heading_pop"
            sx={{ borderBottom: "0px solid #E1E3E6", padding: "0px" }}
          >
            {" "}
            Appointment Details
          </Typography>
        </Box>
        <Box
          className="selctMenu"
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Select
  value={status}
  size="small"
  onChange={handleChangeSelect}
  renderValue={(selected) => (
    <Chip
      label={selected}
      className="text-capitalize"
      sx={{
        border: `1px solid ${colors[selected]}`,
        color: colors[selected],
        fontWeight: "bold",
        backgroundColor: "transparent",
        height: "18px",
        fontSize: "12px",
        ".MuiChip-label": {
          paddingInline: "6px !important",
        },
      }}
    />
  )}
  sx={{
    width: "auto",
    background: "#E1E3E6",
    borderRadius: "50px",
    "svg.MuiSelect-icon, svg.MuiSelect-iconOpen": {
      right: "5px !important",
    },
  }}
  MenuProps={{
    PopoverClasses: {
      root: "custom-popover-class",
    },
    PaperProps: {
      className: "custom-menu-class",
      id: "custom-menu-id",
    },
  }}
>
  {Object.keys(colors).map((item) => (
    <MenuItem
      className="text-capitalize"
      key={item}
      value={item}
      sx={{
        justifyContent: "space-between",
        gap: "8px",
        minWidth: "170px",
      }}
    >
      <Chip
        label={item}
        sx={{
          backgroundColor: "transparent",
          color: colors[item],
          fontWeight: "bold",
          border: `1px solid ${colors[item]}`,
          height: "18px",
          fontSize: "12px",
          ".MuiChip-label": {
            paddingInline: "6px !important",
          },
        }}
      />
      {status === item && (
        <ListItemIcon
          sx={{
            minWidth: "24px !important", // Adjust icon padding
            color: colors[item],
            display: "flex",
            justifyContent: "justify-end",
          }}
        >
          <CheckIcon />
        </ListItemIcon>
      )}
    </MenuItem>
  ))}
</Select>


          <Button
            sx={{
              display: "flex",
              flexFlow: "row nowrap",
              alignItems: "center",
              background: "#F0F1F2",
              padding: "10px 16px",
              gap: "8px",
              borderRadius: "22px",
              color: "#2B2E33",
              fontSize: "14px",
              fontFamily: "Satoshi-Medium",
              lineHeight: "1.45px",
              textTransform: "capitalize",
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.98567 6.10291L5.30484 10.7837L5.08484 13.2471L7.56567 13.0212L12.2323 8.34958L9.98567 6.10291ZM14.9715 5.60791L12.7257 3.36291L11.1023 4.98625L13.3482 7.23291L14.9715 5.60791ZM4.24234 14.9971C4.21651 14.9996 4.19151 15.0004 4.16651 15.0004C3.94651 15.0004 3.73401 14.9137 3.57734 14.7562C3.40234 14.5812 3.31401 14.3379 3.33651 14.0921L3.65234 10.6162C3.68734 10.2354 3.85567 9.87625 4.12651 9.60541L11.6232 2.10791C12.2082 1.52125 13.2698 1.55041 13.8865 2.16625L16.1682 4.44791H16.169C16.8073 5.08708 16.8323 6.10125 16.2257 6.70958L8.72818 14.2071C8.45734 14.4787 8.09901 14.6462 7.71734 14.6812L4.24234 14.9971ZM4.16634 16.6672H15.833C16.2913 16.6672 16.6663 17.0422 16.6663 17.5005C16.6663 17.9588 16.2913 18.3338 15.833 18.3338H4.16634C3.70801 18.3338 3.33301 17.9588 3.33301 17.5005C3.33301 17.0422 3.70801 16.6672 4.16634 16.6672Z"
                fill="#2B2E33"
              />
            </svg>
            Edit
          </Button>
          {/* <Button
            sx={{
              display: "flex",
              flexFlow: "row nowrap",
              alignItems: "center",
              border: "1px solid #C4C7CC",
              borderRadius: "25pX",
              height: "40px",
              width: "40px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              width="4"
              height="16"
              viewBox="0 0 4 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.99967 3.83333C2.91967 3.83333 3.66634 3.08667 3.66634 2.16667C3.66634 1.24667 2.91967 0.5 1.99967 0.5C1.07967 0.5 0.333008 1.24667 0.333008 2.16667C0.333008 3.08667 1.07967 3.83333 1.99967 3.83333ZM1.99967 6.33333C1.07967 6.33333 0.333008 7.08 0.333008 8C0.333008 8.92 1.07967 9.66667 1.99967 9.66667C2.91967 9.66667 3.66634 8.92 3.66634 8C3.66634 7.08 2.91967 6.33333 1.99967 6.33333ZM0.333008 13.8333C0.333008 12.9133 1.07967 12.1667 1.99967 12.1667C2.91967 12.1667 3.66634 12.9133 3.66634 13.8333C3.66634 14.7533 2.91967 15.5 1.99967 15.5C1.07967 15.5 0.333008 14.7533 0.333008 13.8333Z"
                fill="#2B2E33"
              />
            </svg>
          </Button> */}

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              display: "flex",
              flexFlow: "row nowrap",
              alignItems: "center",
              border: "1px solid #C4C7CC",
              borderRadius: "25pX",
              height: "40px",
              width: "40px",
              minWidth: "40px",
              justifyContent: "center",
            }}
          >
            <svg
              width="4"
              height="16"
              viewBox="0 0 4 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.99967 3.83333C2.91967 3.83333 3.66634 3.08667 3.66634 2.16667C3.66634 1.24667 2.91967 0.5 1.99967 0.5C1.07967 0.5 0.333008 1.24667 0.333008 2.16667C0.333008 3.08667 1.07967 3.83333 1.99967 3.83333ZM1.99967 6.33333C1.07967 6.33333 0.333008 7.08 0.333008 8C0.333008 8.92 1.07967 9.66667 1.99967 9.66667C2.91967 9.66667 3.66634 8.92 3.66634 8C3.66634 7.08 2.91967 6.33333 1.99967 6.33333ZM0.333008 13.8333C0.333008 12.9133 1.07967 12.1667 1.99967 12.1667C2.91967 12.1667 3.66634 12.9133 3.66634 13.8333C3.66634 14.7533 2.91967 15.5 1.99967 15.5C1.07967 15.5 0.333008 14.7533 0.333008 13.8333Z"
                fill="#2B2E33"
              />
            </svg>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              className: "custom-menu-class", // Ensure this class is defined in your styles
            }}
            PopoverClasses={{
              root: "custom-popover-class", // Ensure this class is defined in your styles
            }}
          >
            <MenuItem
              onClick={handleClose}
              sx={{ color: "#2B2E33", gap: "10px" }}
            >
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.00572 7.08961C3.67988 7.41544 3.15322 7.41544 2.82738 7.08961L0.327383 4.58961C0.00154948 4.26378 0.00154948 3.73711 0.327383 3.41128L2.82738 0.911276C2.98988 0.748775 3.20322 0.667109 3.41655 0.667109C3.62988 0.667109 3.84322 0.748775 4.00572 0.911276C4.33155 1.23711 4.33155 1.76378 4.00572 2.08961L2.92822 3.16711H12.9249C14.5749 3.16711 15.9165 4.48628 15.9165 6.10794V8.16711C15.9165 8.62794 15.544 9.00044 15.0832 9.00044C14.6224 9.00044 14.2499 8.62794 14.2499 8.16711V6.10794C14.2499 5.40544 13.6557 4.83378 12.9249 4.83378H2.92822L4.00572 5.91128C4.33155 6.23711 4.33155 6.76378 4.00572 7.08961ZM13.0714 14.8338H3.07472C1.42556 14.8338 0.0830578 13.5146 0.0830578 11.8929L0.0830578 9.83378C0.0830578 9.37294 0.455558 9.00044 0.916391 9.00044C1.37722 9.00044 1.74972 9.37294 1.74972 9.83378V11.8929C1.74972 12.5946 2.34389 13.1671 3.07472 13.1671H13.0714L11.9939 12.0896C11.6689 11.7638 11.6689 11.2371 11.9939 10.9113C12.1564 10.7488 12.3697 10.6671 12.5831 10.6671C12.7964 10.6671 13.0097 10.7488 13.1722 10.9113L15.6722 13.4113C15.9981 13.7371 15.9981 14.2638 15.6722 14.5896L13.1722 17.0896C12.8472 17.4154 12.3197 17.4154 11.9939 17.0896C11.6689 16.7638 11.6689 16.2371 11.9939 15.9113L13.0714 14.8338Z"
                  fill="#898E99"
                />
              </svg>
              Rebook
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              sx={{ color: "#C81E1E", gap: "10px" }}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.9998 14.8327C13.9998 15.2918 13.6265 15.666 13.1665 15.666H4.83317C4.37317 15.666 3.99984 15.2918 3.99984 14.8327V5.66602H13.9998V14.8327ZM7.33317 2.60602C7.33317 2.47685 7.5115 2.33268 7.74984 2.33268H10.2498C10.4882 2.33268 10.6665 2.47685 10.6665 2.60602V3.99935H7.33317V2.60602ZM16.4998 3.99935H15.6665H12.3332V2.60602C12.3332 1.53602 11.399 0.666016 10.2498 0.666016H7.74984C6.60067 0.666016 5.6665 1.53602 5.6665 2.60602V3.99935H2.33317H1.49984C1.0415 3.99935 0.666504 4.37435 0.666504 4.83268C0.666504 5.29102 1.0415 5.66602 1.49984 5.66602H2.33317V14.8327C2.33317 16.211 3.45484 17.3327 4.83317 17.3327H13.1665C14.5448 17.3327 15.6665 16.211 15.6665 14.8327V5.66602H16.4998C16.9582 5.66602 17.3332 5.29102 17.3332 4.83268C17.3332 4.37435 16.9582 3.99935 16.4998 3.99935Z"
                  fill="#C81E1E"
                />
              </svg>
              Delete
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <span
        className="crosssicon"
        onClick={() => props.setBookAppointmentToggle(false)}
      >
        <ClearIcon />
      </span>
      <Box
        className="AppointPop"
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          // maxWidth: "500px",
          margin: "0 auto",
          padding: "20px",
          boxShadow: 3,
          borderRadius: "12px",
          bgcolor: "#ffffff",
        }}
      >
        <div className="customer-card custcustomer-card">
          <div className="card-header">
            <div className="card-header-left ">
              <h2>
                {getData?.first_name} {getData?.last_name}
              </h2>
              <span className="newbg">New</span>
            </div>
            <div className="card-header-left card-header-rit">
              <span className="loyalty-tier loyalty-inner">
                Loyalty Points:{" "}
                <span className="low">{getData?.points_balance}</span>
              </span>
              <span className="loyalty-tier">
                Loyalty Tier:
                <span className="low">{getData?.loyalty_tier}</span>
              </span>
            </div>
          </div>
          <p className="phone-email">
            <span>{getData.phone_number_mobile}</span> ·{" "}
            <span>{getData.email}</span>
          </p>
          <div className="card-actions">
            {/* <button className="btn smallspan">Notes (2)</button> */}
            <button className="btn smallspan">
              Notes ({getData?.notes?.length})
            </button>
            <button className="btn smallspan">History (0)</button>
            <button className="btn smallspan">Check Out</button>
            <button className="btn smallspan">Edit</button>
          </div>
          <div className="card-actions bottom-card-actions">
            <div className="bottom-card-actions-lft">
              <p>
                <img src={clipboardIcon} alt="Clipboard Icon" /> Total Visits
                <span>{selectedCustomer.totalVisits || 0}</span>
              </p>
              <p>
                <img src={eyeoffIcon} alt="Eye Off Icon" />
                No Shows<span>{selectedCustomer.noShows || 0}</span>
              </p>
              <p>
                <img src={slashIcon} alt="Slash Icon" />
                Cancellations <span>{selectedCustomer.cancellations || 0}</span>
              </p>
            </div>
            <div className="bottom-card-actions-rit">
              <p>
                <img src={dollorIcon} alt="Dollar Icon" />
                Deposit<span>${getData?.deposit}</span>
              </p>
            </div>
          </div>
        </div>
        {/* tab section */}
        {appointments &&
          appointments?.map((item, index) => (
            <Box sx={{ width: "100%", marginBlock: "18px" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChangeTab}
                    aria-label="lab API tabs example"
                    className="detailtab"
                    sx={{
                      minHeight: "auto",
                      "& .MuiTabs-flexContainer": {
                        gap: { xs: "8px", sm: "16px", md: "32px" },
                      },
                    }}
                  >
                    <Tab
                      icon={<InfoOutlinedIcon />}
                      iconPosition="start"
                      label="Details"
                      value="1"
                      style={{
                        color: "#6B7280",
                        fontSize: "14px",
                        fontFamily: "Satoshi-Medium",
                        textTransform: "capitalize",
                        paddingInline: "0px",
                        minWidth: "auto",
                        width: "auto",
                        minHeight: "auto",
                      }}
                    />
                    <Tab
                      icon={<ListOutlinedIcon />}
                      iconPosition="start"
                      label="Timeline"
                      value="2"
                      style={{
                        color: "#6B7280",
                        fontSize: "14px",
                        fontFamily: "Satoshi-Medium",
                        textTransform: "capitalize",
                        paddingInline: "0px",
                        minWidth: "auto",
                        width: "auto",
                        minHeight: "auto",
                      }}
                    />
                    <Tab
                      icon={<EventNoteOutlinedIcon />}
                      iconPosition="start"
                      label="Appointment Notes (1)"
                      value="3"
                      style={{
                        color: "#6B7280",
                        fontSize: "14px",
                        fontFamily: "Satoshi-Medium",
                        textTransform: "capitalize",
                        paddingInline: "0px",
                        minWidth: "auto",
                        width: "auto",
                        minHeight: "auto",
                      }}
                    />
                  </TabList>
                </Box>
                <TabPanel value="1" sx={{ padding: "24px 0px" }}>
                  <Box className="details">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="h5"
                          style={{
                            color: "#40444D",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Regular",
                            marginBottom: "4px",
                          }}
                        >
                          Service
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            color: "#1F1F1F",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Medium",
                          }}
                        >
                          {item?.ServiceName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="h5"
                          style={{
                            color: "#40444D",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Regular",
                            marginBottom: "4px",
                          }}
                        >
                          Specialist
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            color: "#1F1F1F",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Medium",
                          }}
                        >
                          {item?.SpecialistName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="h5"
                          style={{
                            color: "#40444D",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Regular",
                            marginBottom: "4px",
                          }}
                        >
                          Price
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            color: "#1F1F1F",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Medium",
                          }}
                        >
                          {item?.Price}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="h5"
                          style={{
                            color: "#40444D",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Regular",
                            marginBottom: "4px",
                          }}
                        >
                          Date
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            color: "#1F1F1F",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Medium",
                          }}
                        >
                          {formatDate(item?.CreatedAt)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="h5"
                          style={{
                            color: "#40444D",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Regular",
                            marginBottom: "4px",
                          }}
                        >
                          Time
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            color: "#1F1F1F",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Medium",
                          }}
                        >
                          {item?.StartTime}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="h5"
                          style={{
                            color: "#40444D",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Regular",
                            marginBottom: "4px",
                          }}
                        >
                          Duration
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            color: "#1F1F1F",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Medium",
                          }}
                        >
                          {item?.Duration}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="h5"
                          style={{
                            color: "#40444D",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Regular",
                            marginBottom: "4px",
                          }}
                        >
                          Repeat By
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            color: "#1F1F1F",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Medium",
                          }}
                        >
                          {item?.Repeat}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="h5"
                          style={{
                            color: "#40444D",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Regular",
                            marginBottom: "4px",
                          }}
                        >
                          Boooked By
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            color: "#1F1F1F",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Medium",
                          }}
                        >
                          26 Jul 2024
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          variant="h5"
                          style={{
                            color: "#40444D",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Regular",
                            marginBottom: "4px",
                          }}
                        >
                          Booking channel
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            color: "#1F1F1F",
                            fontSize: "14px",
                            fontFamily: "Satoshi-Medium",
                          }}
                        >
                          {item?.BookingChannel}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
                <TabPanel value="2" sx={{ padding: "24px 0px" }}>
                  {/* timeLine component */}
                  {/* <CustomTimeline events={events} /> */}
                  <Timeline
                    position="right"
                    sx={{
                      padding: 0, // Remove Timeline padding
                    }}
                  >
                    {getNotes.map((event, index) => (
                      <TimelineItem
                        key={index}
                        sx={{
                          padding: 0, // Remove padding from each TimelineItem
                          "::before": {
                            display: "none", // Remove the default left margin space
                          },
                        }}
                      >
                        <TimelineSeparator>
                          <TimelineDot
                            sx={{
                              backgroundColor: "#F0F1F2", // Set background color of the dot
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "32px",
                              height: "32px",
                              flexShrink: 0,
                              boxShadow: "none",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#F0F1F2",
                              }}
                            >
                              {/* {event.icon} */}
                              <svg
                                width="16"
                                height="18"
                                viewBox="0 0 16 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M13.8333 14.8327C13.8333 15.2927 13.4592 15.666 13 15.666H3C2.54083 15.666 2.16667 15.2927 2.16667 14.8327V5.66602C2.16667 5.20602 2.54083 4.83268 3 4.83268V5.66602C3 6.58518 3.7475 7.33268 4.66667 7.33268H11.3333C12.2525 7.33268 13 6.58518 13 5.66602V4.83268C13.4592 4.83268 13.8333 5.20602 13.8333 5.66602V14.8327ZM4.66667 2.33268L11.3333 2.33518V3.16602V5.66602H4.66667V3.16602V2.33268ZM13 3.16602V2.33268C13 1.41352 12.2525 0.666016 11.3333 0.666016H4.66667C3.7475 0.666016 3 1.41352 3 2.33268V3.16602C1.62167 3.16602 0.5 4.28768 0.5 5.66602V14.8327C0.5 16.211 1.62167 17.3327 3 17.3327H13C14.3783 17.3327 15.5 16.211 15.5 14.8327V5.66602C15.5 4.28768 14.3783 3.16602 13 3.16602Z"
                                  fill="#898E99"
                                />
                              </svg>
                            </span>
                          </TimelineDot>
                          {index < getNotes.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent sx={{ marginLeft: 0 }}>
                          {" "}
                          {/* Align content to the left */}
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="#2B2E33"
                            fontSize={"14px"}
                            fontFamily={"Satoshi-Regular !important"}
                          >
                            {event.action}
                          </Typography>
                          {event.message && (
                            <Paper
                              elevation={1}
                              sx={{
                                padding: "10px",
                                marginY: "8px",
                                backgroundColor: "#F0F1F2",
                                boxShadow: "none",
                                borderRadius: "12px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  fontSize: "12px",
                                  color: "#2B2E33",
                                  fontFamily: "Satoshi-Medium !important",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Button
                                  sx={{
                                    padding: 0,
                                    margin: 0,
                                    minWidth: "auto",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M10.6786 7.11944L8.88195 5.32278L10.1806 4.02344L11.9766 5.81944L10.6786 7.11944ZM6.05261 11.7501L4.06795 11.9308L4.24395 9.96011L7.98861 6.21544L9.78595 8.01278L6.05261 11.7501ZM12.9353 4.89211L12.9346 4.89144L11.1093 3.06611C10.6153 2.57344 9.76661 2.55011 9.29861 3.02011L3.30128 9.01744C3.08395 9.23411 2.94928 9.52211 2.92128 9.82678L2.66861 12.6068C2.65128 12.8034 2.72128 12.9981 2.86128 13.1381C2.98728 13.2641 3.15728 13.3334 3.33261 13.3334C3.35328 13.3334 3.37328 13.3328 3.39328 13.3308L6.17328 13.0781C6.47861 13.0501 6.76595 12.9161 6.98261 12.6994L12.9806 6.70144C13.4659 6.21478 13.4453 5.40278 12.9353 4.89211Z"
                                      fill="#898E99"
                                    />
                                  </svg>
                                </Button>
                                {event.message}
                              </Typography>
                            </Paper>
                          )}
                          {event.createdAt && (
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {formatDate(event.createdAt)}
                            </Typography>
                          )}
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </TabPanel>
                {item.AppointmentNotes.map((note) => (
                  <TabPanel value="3" sx={{ padding: "24px 0px" }}>
                    <Box
                      className="customerNote"
                      style={{
                        background: "#F0F1F2",
                        padding: "16px",
                        borderRadius: "12px",
                        position: "relative",
                        marginBottom: "16px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          color: "#2B2E33",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          marginBottom: "8px",
                          lineHeight: " 1.5",
                        }}
                      >
                        {note?.note}
                        <span> {formatDate(note?.createdAt)}</span>
                      </Typography>

                      <Typography
                        className="sideDot"
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "16px",
                        }}
                      >
                        <Button>
                          <img
                            src={dotButtonIcon}
                            style={{ width: "32px", height: "32px" }}
                            alt="dotButtonIcon"
                          />
                        </Button>
                      </Typography>
                    </Box>
                  </TabPanel>
                ))}
              </TabContext>
            </Box>
          ))}
      </Box>
      {openCreatCustomerModal && (
        <>
          <AddCustomer
            addCustomerToogle={addCustomerToggle}
            setAddCustomerToggle={setAddCustomerToggle}
          />
          <AddCustomer
            addCustomerToogle={addCustomerToggle}
            setAddCustomerToggle={setAddCustomerToggle}
          />
        </>
      )}
    </LocalizationProvider>
  );
};

export default BookAppointmentDetail;
