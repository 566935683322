import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    IconButton,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import { deleteAppointment, fetchAllAppointment } from '../store/appointment';
import { useDispatch } from 'react-redux';
import { fetchSpecialists } from '../store/specialist';
import "./deletModel.css";
import DeletIcon from "../assets/images/icon/deleticon.png";


const DeleteConfirmationModal = ({ open, onClose, onConfirm, selectedEventId }) => {
    const dispatch = useDispatch()
    // function handleDelete() {
    //     dispatch(deleteAppointment(selectedEventId))
    //     onClose(onClose)
    //     dispatch(fetchAllAppointment());   
    // }

    function handleDelete() {
        dispatch(deleteAppointment(selectedEventId))
          .unwrap()
          .then((response) => {    
            if (response.data.success == true) {
                onClose()
              dispatch(fetchAllAppointment()); 
            } else {
              console.error("Delete failed:", response);
            }
          })
          .catch((error) => {
            console.error("An error occurred while deleting the appointment:", error);
          })
      }
      

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth className='deletmodal'>
            {/* Close Icon */}
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 6,
                    top: 7,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Modal Content */}
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    pt: 0,
                }}
            >
               <img src={DeletIcon} alt="Delet Icon"  className='deleticon'/>
                <Typography variant="h6" fontWeight="bold">
                    Are you sure you want to delete this appointment?
                </Typography>
            </DialogContent>

            {/* Buttons */}
            <DialogActions sx={{ justifyContent: 'center', pb: 0 }} className='deletbtn'>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDelete}
                    sx={{
                        borderRadius: '20px',
                        px: 3,
                        py: 1,
                    }}className='deletbtnBlck'
                >
                    Yes, I’m sure
                </Button>
                <Button
                    variant="outlined"
                    color="inherit"
                    onClick={onClose}
                    sx={{
                        borderRadius: '20px',
                        px: 3,
                        py: 1,
                    }}
                    className='deletbtntranspart'
                >
                    No, Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationModal;
