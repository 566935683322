import React, { useEffect, useRef, useState } from "react";
import Drawer from '@mui/material/Drawer';
import CreateAppointment from "./createAppointment";
import BookAppointmentDetail from "./BookAppointmentDetail";
import RebookDetails from "./Rebookdetail";


export default function Rebook({
    rebook,
    setRebook
}) {

    return (
        <>
            <Drawer
                anchor={"right"}
                open={rebook}
                sx={{
                    "& .MuiDrawer-paper":{
                        maxWidth:"900px !important"
                    }
                }}
            >
                <RebookDetails 
                    rebook={rebook}
                    setRebook={setRebook}/>
            </Drawer>

        </>
    )
}