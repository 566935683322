import React, { useEffect, useState } from "react";
import Link from "@material-ui/core/Link";
import { useDispatch, useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import femaleIcon from "../assets/images/icon/ic_round-female.png";
import dotButtonIcon from "../assets/images/dotButton.png";
import pantoneIcon from "../assets/images/pantone.png";
import allergyIcon from "../assets/images/allergy.png";
import downloadIcon from "../assets/images/icon/download.png";
import fileIcon from "../assets/images/icon/file.png";
import AddIcon from "@mui/icons-material/Add";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";
import oragdotIcon from "../assets/images/icon/03dot.png";
import EditIcon from "@mui/icons-material/Edit";
import deleticon from "../assets/images/deleticon.png";

import { Button, Typography, Box } from "@mui/material";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import "./appointment.css";
import dollorIcon from "../assets/images/dollar.png";
import { Grid, TableFooter } from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import filetextIcon from "../assets/images/file-text.png";
import AddCustomer from "./addCustomer";
import "./addCustomerDetail.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "../appointment/addCustomerDetail.css";
import CustomTimeline from "../components/CustomTimeline";
import AddCustomerNotes from "../Modals/AddCustomerNotes";
import LoyaltyPointModal from "../Modals/LoyaltyPointModal";
import Tooltip from "@mui/material/Tooltip";
import { getSingleCustomer } from "../store/getCustmerById";
import { getAppointmentDetail } from "../store/getAppointmentById";
import { getCustomerAppointment } from "../store/getCustomerAppointment";
import { deleteCustomerNotes, getCustomerNotes } from "../store/getCustomerNotes";
import { getCustomerTimeline } from "../store/getCustomerTimeline";

const AddCustomerDetail = (props) => {
  // selectedEventId
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("1");
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [addCustomerToggle, setAddCustomerToggle] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openLoyaltyModal, setOpenLoyaltyModal] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);
  const handleOpenLoyaltyModal = () => setOpenLoyaltyModal(true);
  const handleCloseLoyaltyModal = () => setOpenLoyaltyModal(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const toggleDeleteOption = () => {
    setShowDelete((prev) => !prev);
  };

  const toggleAppointmentOption = () => {
    setShowAppointment((prev) => !prev);
  };

  const toggleMoreOption = (index) => {
    setShowMore((prev) => (prev === index ? null : index));
  };

  const openCreatCustomerModal = () => {
    setAddCustomerToggle(true);
    setOpenCustomerModal(true);
  };
  const allData = useSelector(
    (state) => state?.getAppointmentDetailReducer.all
  );
  const appointments = allData?.data?.data;

  const getData = useSelector(
    (state) => state?.getSingleCustomerReducer?.all?.data
  );

  const customerAppointments = useSelector(
    (state) => state?.getCustomerAppointmentReducer?.all?.data?.data
  );
  const customerNotes = useSelector(
    (state) => state?.getCustomerNotesReducer?.byId?.data.notes
  );

  const customerTimeline = useSelector(
    (state) => state?.getCustomerTimelineReducer?.all?.data
  );
  
  useEffect(() => {
    if (props.selectedEventId) {
      dispatch(getAppointmentDetail(props.selectedEventId));
    }
  }, [props.selectedEventId]);

  useEffect(() => {
    if (appointments) {
      dispatch(getSingleCustomer(appointments[0]?.CustomerId));
    }
  }, [appointments]);

  useEffect(() => {
    if (appointments) {
      dispatch(getCustomerAppointment(appointments[0]?.CustomerId));
    }
  }, [appointments]);

  
  useEffect(() => {
    if (appointments) {
      dispatch(getCustomerNotes(appointments[0]?.CustomerId));
    }
  }, [appointments]);

  useEffect(() => {
    if (appointments) {
     dispatch(getCustomerTimeline(appointments[0]?.CustomerId))
    }
  }, [appointments]);

  function handleDelete(customerId) { 
    dispatch(deleteCustomerNotes(customerId))
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  // timeLine
  const events = [
    {
      icon: (
        <svg
          width="14"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.0004 10.666H4.5004C4.0404 10.666 3.66707 10.2927 3.66707 9.83268C3.66707 9.37268 4.0404 8.99935 4.5004 8.99935H7.0004C7.4604 8.99935 7.83373 9.37268 7.83373 9.83268C7.83373 10.2927 7.4604 10.666 7.0004 10.666ZM3.66707 13.166C3.66707 12.706 4.0404 12.3327 4.5004 12.3327H9.5004C9.96123 12.3327 10.3337 12.706 10.3337 13.166C10.3337 13.626 9.96123 13.9993 9.5004 13.9993H4.5004C4.0404 13.9993 3.66707 13.626 3.66707 13.166ZM11.5373 15.666H2.46315C2.20815 15.666 2.00065 15.4793 2.00065 15.2493V2.74935C2.00065 2.51935 2.20815 2.33268 2.46315 2.33268H7.00065V4.95768C7.00065 6.26768 8.01482 7.33268 9.26232 7.33268H12.0007V15.2493C12.0007 15.4793 11.7932 15.666 11.5373 15.666ZM8.66732 3.14768L10.9523 5.66602H9.26232C8.93398 5.66602 8.66732 5.34852 8.66732 4.95768V3.14768ZM13.4506 5.93935L8.91398 0.939349C8.75565 0.765182 8.53232 0.666016 8.29648 0.666016H2.46315C1.28898 0.666016 0.333984 1.60102 0.333984 2.74935V15.2493C0.333984 16.3977 1.28898 17.3327 2.46315 17.3327H11.5373C12.7115 17.3327 13.6673 16.3977 13.6673 15.2493V6.49935C13.6673 6.29185 13.5898 6.09268 13.4506 5.93935Z"
            fill="#898E99"
          />
        </svg>
      ),
      title: (
        <span>
          <span style={{ color: "#1A56DB" }}>Maria Doe</span>
          <span> added </span>
          <span style={{ color: "#1A56DB" }}>BIAB with shellac & removal</span>
          <span> note</span>
        </span>
      ),
      description:
        "Angela did not turn up for her 3PM appointment and left without notifying us about it.",
      timestamp: "Apr 19, 2024, 3PM",
      color: "secondary",
    },
    {
      icon: (
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.8333 14.8327C13.8333 15.2927 13.4592 15.666 13 15.666H3C2.54083 15.666 2.16667 15.2927 2.16667 14.8327V5.66602C2.16667 5.20602 2.54083 4.83268 3 4.83268V5.66602C3 6.58518 3.7475 7.33268 4.66667 7.33268H11.3333C12.2525 7.33268 13 6.58518 13 5.66602V4.83268C13.4592 4.83268 13.8333 5.20602 13.8333 5.66602V14.8327ZM4.66667 2.33268L11.3333 2.33518V3.16602V5.66602H4.66667V3.16602V2.33268ZM13 3.16602V2.33268C13 1.41352 12.2525 0.666016 11.3333 0.666016H4.66667C3.7475 0.666016 3 1.41352 3 2.33268V3.16602C1.62167 3.16602 0.5 4.28768 0.5 5.66602V14.8327C0.5 16.211 1.62167 17.3327 3 17.3327H13C14.3783 17.3327 15.5 16.211 15.5 14.8327V5.66602C15.5 4.28768 14.3783 3.16602 13 3.16602Z"
            fill="#898E99"
          />
        </svg>
      ),
      title: (
        <span>
          <span style={{ color: "#1A56DB" }}>BIAB with shellac & removal </span>
          <span> changed status to No-Show </span>
        </span>
      ),
      timestamp: "In 12h",
      color: "primary",
    },
    {
      icon: (
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.8333 14.8327C13.8333 15.2927 13.4592 15.666 13 15.666H3C2.54083 15.666 2.16667 15.2927 2.16667 14.8327V5.66602C2.16667 5.20602 2.54083 4.83268 3 4.83268V5.66602C3 6.58518 3.7475 7.33268 4.66667 7.33268H11.3333C12.2525 7.33268 13 6.58518 13 5.66602V4.83268C13.4592 4.83268 13.8333 5.20602 13.8333 5.66602V14.8327ZM4.66667 2.33268L11.3333 2.33518V3.16602V5.66602H4.66667V3.16602V2.33268ZM13 3.16602V2.33268C13 1.41352 12.2525 0.666016 11.3333 0.666016H4.66667C3.7475 0.666016 3 1.41352 3 2.33268V3.16602C1.62167 3.16602 0.5 4.28768 0.5 5.66602V14.8327C0.5 16.211 1.62167 17.3327 3 17.3327H13C14.3783 17.3327 15.5 16.211 15.5 14.8327V5.66602C15.5 4.28768 14.3783 3.16602 13 3.16602Z"
            fill="#898E99"
          />
        </svg>
      ),
      title: (
        <span>
          <span style={{ color: "#1A56DB" }}>BIAB with shellac & removal </span>
          <span> will take place </span>
        </span>
      ),
      timestamp: "In 12h",
      color: "primary",
    },
    {
      icon: (
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.8333 14.8327C13.8333 15.2927 13.4592 15.666 13 15.666H3C2.54083 15.666 2.16667 15.2927 2.16667 14.8327V5.66602C2.16667 5.20602 2.54083 4.83268 3 4.83268V5.66602C3 6.58518 3.7475 7.33268 4.66667 7.33268H11.3333C12.2525 7.33268 13 6.58518 13 5.66602V4.83268C13.4592 4.83268 13.8333 5.20602 13.8333 5.66602V14.8327ZM4.66667 2.33268L11.3333 2.33518V3.16602V5.66602H4.66667V3.16602V2.33268ZM13 3.16602V2.33268C13 1.41352 12.2525 0.666016 11.3333 0.666016H4.66667C3.7475 0.666016 3 1.41352 3 2.33268V3.16602C1.62167 3.16602 0.5 4.28768 0.5 5.66602V14.8327C0.5 16.211 1.62167 17.3327 3 17.3327H13C14.3783 17.3327 15.5 16.211 15.5 14.8327V5.66602C15.5 4.28768 14.3783 3.16602 13 3.16602Z"
            fill="#898E99"
          />
        </svg>
      ),
      title: (
        <span>
          <span>100 Loyalty Points have been added</span>
        </span>
      ),
      timestamp: "Apr 19, 2024, 11AM ",
      color: "primary",
    },
    {
      icon: (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.833 6.16732H7.83301V1.16732C7.83301 0.706484 7.45967 0.333984 6.99967 0.333984C6.53967 0.333984 6.16634 0.706484 6.16634 1.16732V6.16732H1.16634C0.706341 6.16732 0.333008 6.53982 0.333008 7.00065C0.333008 7.46148 0.706341 7.83398 1.16634 7.83398H6.16634V12.834C6.16634 13.2948 6.53967 13.6673 6.99967 13.6673C7.45967 13.6673 7.83301 13.2948 7.83301 12.834V7.83398H12.833C13.293 7.83398 13.6663 7.46148 13.6663 7.00065C13.6663 6.53982 13.293 6.16732 12.833 6.16732Z"
            fill="#898E99"
          />
        </svg>
      ),
      title: (
        <span>
          <span style={{ color: "#1A56DB" }}>BIAB with shellac & removal </span>
          <span>has been created by </span>
          <span style={{ color: "#1A56DB" }}>Maria Doe</span>
        </span>
      ),
      timestamp: "Apr 19, 2024, 11AM ",
      color: "success",
    },
    {
      icon: (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.833 6.16732H7.83301V1.16732C7.83301 0.706484 7.45967 0.333984 6.99967 0.333984C6.53967 0.333984 6.16634 0.706484 6.16634 1.16732V6.16732H1.16634C0.706341 6.16732 0.333008 6.53982 0.333008 7.00065C0.333008 7.46148 0.706341 7.83398 1.16634 7.83398H6.16634V12.834C6.16634 13.2948 6.53967 13.6673 6.99967 13.6673C7.45967 13.6673 7.83301 13.2948 7.83301 12.834V7.83398H12.833C13.293 7.83398 13.6663 7.46148 13.6663 7.00065C13.6663 6.53982 13.293 6.16732 12.833 6.16732Z"
            fill="#898E99"
          />
        </svg>
      ),
      title: (
        <span>
          <span>Customer has been created by </span>
          <span style={{ color: "#1A56DB" }}>Maria Doe</span>
        </span>
      ),
      timestamp: "Apr 19, 2024, 11AM ",
      color: "success",
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      weekday: "short", // Short weekday name (e.g., Fri)
      day: "2-digit", // Day of the month (e.g., 26)
      month: "short", // Short month name (e.g., Jul)
      year: "numeric", // Full year (e.g., 2024)
    }).format(date);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} className="kk">
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          borderBottom: "1px solid #E1E3E6",
          justifyContent: "space-between",
          alignitem: "center",
        }}
        className="customerdetailpop pd19 Heading_popOuter_withCloseIocn"
      >
        <Box>
          <Typography
            variant="span"
            className="Heading_pop"
            sx={{ borderBottom: "0px solid #E1E3E6", padding: "0px" }}
          >
            {" "}
            Customer Details
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignitem: "center",
            gap: "8px",
          }}
        >
          <Button
            sx={{
              display: "flex",
              flexFlow: "row nowrap",
              alignitem: "center",
              background: "#F0F1F2",
              padding: "10px 16px",
              gap: "8px",
              borderRadius: "22px",
              color: "#2B2E33",
              fontSize: "14px",
              fontFamily: "Satoshi-Medium",
              lineHeight: "1.45px",
              textTransform: "capitalize",
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.98567 6.10291L5.30484 10.7837L5.08484 13.2471L7.56567 13.0212L12.2323 8.34958L9.98567 6.10291ZM14.9715 5.60791L12.7257 3.36291L11.1023 4.98625L13.3482 7.23291L14.9715 5.60791ZM4.24234 14.9971C4.21651 14.9996 4.19151 15.0004 4.16651 15.0004C3.94651 15.0004 3.73401 14.9137 3.57734 14.7562C3.40234 14.5812 3.31401 14.3379 3.33651 14.0921L3.65234 10.6162C3.68734 10.2354 3.85567 9.87625 4.12651 9.60541L11.6232 2.10791C12.2082 1.52125 13.2698 1.55041 13.8865 2.16625L16.1682 4.44791H16.169C16.8073 5.08708 16.8323 6.10125 16.2257 6.70958L8.72818 14.2071C8.45734 14.4787 8.09901 14.6462 7.71734 14.6812L4.24234 14.9971ZM4.16634 16.6672H15.833C16.2913 16.6672 16.6663 17.0422 16.6663 17.5005C16.6663 17.9588 16.2913 18.3338 15.833 18.3338H4.16634C3.70801 18.3338 3.33301 17.9588 3.33301 17.5005C3.33301 17.0422 3.70801 16.6672 4.16634 16.6672Z"
                fill="#2B2E33"
              />
            </svg>
            Edit
          </Button>
          <Button
            className="dotPopup"
            sx={{
              display: "flex",
              flexFlow: "row nowrap",
              border: "1px solid #C4C7CC",
              borderRadius: "25pX",
              height: "40px",
              width: "40px",
              minWidth: "40px",
              justifyContent: "center",
              alignitem: "center",
            }}
            onClick={toggleDeleteOption}
          >
            <svg
              width="4"
              height="16"
              viewBox="0 0 4 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.99967 3.83333C2.91967 3.83333 3.66634 3.08667 3.66634 2.16667C3.66634 1.24667 2.91967 0.5 1.99967 0.5C1.07967 0.5 0.333008 1.24667 0.333008 2.16667C0.333008 3.08667 1.07967 3.83333 1.99967 3.83333ZM1.99967 6.33333C1.07967 6.33333 0.333008 7.08 0.333008 8C0.333008 8.92 1.07967 9.66667 1.99967 9.66667C2.91967 9.66667 3.66634 8.92 3.66634 8C3.66634 7.08 2.91967 6.33333 1.99967 6.33333ZM0.333008 13.8333C0.333008 12.9133 1.07967 12.1667 1.99967 12.1667C2.91967 12.1667 3.66634 12.9133 3.66634 13.8333C3.66634 14.7533 2.91967 15.5 1.99967 15.5C1.07967 15.5 0.333008 14.7533 0.333008 13.8333Z"
                fill="#2B2E33"
              />
            </svg>
            {showDelete && (
              <Box
                className="deletpopup"
                sx={{
                  mt: 2,
                  padding: "6px 10px",
                  border: "0px solid #C81E1E",
                  borderRadius: "8px",
                  color: "C81E1E",
                  backgroundColor: "#ffffff",
                  top: "28px",
                  position: "absolute",
                  fontSize: "14px",
                  right: "0px",
                  textTransform: "capitalize",
                  fontFamily: "Plus Jakarta Sans!important",
                  minWidth: "84px",
                }}
              >
                <Typography
                  variant="body1"
                  color="error"
                  sx={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  <img src={deleticon} alt="delete" /> Delete
                </Typography>
              </Box>
            )}
          </Button>
        </Box>
      </Box>
      <span
        className="crosssicon"
        onClick={() => props.setCustomerDetailToggle(false)}
      >
        <ClearIcon />
      </span>
      <Box
        className="AppointPop addcustomer addcustomerdetail"
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          margin: "0 auto",
          padding: "20px",
          boxShadow: 3,
          borderRadius: "12px",
          bgcolor: "#ffffff",
        }}
      >
        <Box className="AppointPop ">
          <div className="customer-card custcustomer-card">
            <div className="card-header">
              <div className="card-header-left ">
                <h2>
                  {getData?.first_name}
                  {getData?.last_name}
                </h2>
                <span className="newbg">New</span>
              </div>
              <div className="card-header-left card-header-rit btmtooltip customerDetail">
                <span
                  className="loyalty-tier loyalty-inner"
                  onClick={handleOpenLoyaltyModal}
                >
                  Loyalty Points:{" "}
                  <Tooltip title="Click to change" arrow>
                    <Button>
                      <span className="low">{getData?.points_balance}</span>
                    </Button>
                  </Tooltip>
                </span>
                <span className="loyalty-tier">
                  Loyalty Tier:
                  <Tooltip title="Click to change" arrow>
                    <Button>
                      <span className="low">{getData?.loyalty_tier}</span>
                    </Button>
                  </Tooltip>
                </span>
              </div>
            </div>
            <p className="phone-email" style={{ marginBottom: "0px" }}>
              <span>{getData?.phone_number_mobile}</span>
              <span style={{ padding: "2px 4px" }}>.</span>
              <span>{getData?.email}</span>
            </p>
          </div>
          {/* tab section */}

          <Box sx={{ width: "100%", Typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                  className="detailtab"
                >
                  <Tab
                    icon={<InfoOutlinedIcon />}
                    iconPosition="start"
                    label="Details"
                    value="1"
                    // style={{color:"#6B7280",fontSize:"14px",fontFamily:"Satoshi-Medium",textTransform:"capitalize"}}
                    style={{
                      color: "#6B7280",
                      fontSize: "14px",
                      fontFamily: "Satoshi-Medium",
                      textTransform: "capitalize",
                      paddingInline: "0px",
                      minWidth: "auto",
                      width: "auto",
                      minHeight: "auto",
                    }}
                  />
                  <Tab
                    icon={<EventNoteOutlinedIcon />}
                    iconPosition="start"
                    label="Appointments"
                    value="2"
                    // style={{color:"#6B7280",fontSize:"14px",fontFamily:"Satoshi-Medium",textTransform:"capitalize"}}
                    style={{
                      color: "#6B7280",
                      fontSize: "14px",
                      fontFamily: "Satoshi-Medium",
                      textTransform: "capitalize",
                      paddingInline: "0px",
                      minWidth: "auto",
                      width: "auto",
                      minHeight: "auto",
                    }}
                  />
                  <Tab
                    icon={<DescriptionOutlinedIcon />}
                    iconPosition="start"
                    label="Customer Notes (2)"
                    value="3"
                    // style={{color:"#6B7280",fontSize:"14px",fontFamily:"Satoshi-Medium",textTransform:"capitalize"}}
                    style={{
                      color: "#6B7280",
                      fontSize: "14px",
                      fontFamily: "Satoshi-Medium",
                      textTransform: "capitalize",
                      paddingInline: "0px",
                      minWidth: "auto",
                      width: "auto",
                      minHeight: "auto",
                    }}
                  />
                  <Tab
                    icon={<InsertDriveFileOutlinedIcon />}
                    iconPosition="start"
                    label="Files (1)"
                    value="4"
                    // style={{color:"#6B7280",fontSize:"14px",fontFamily:"Satoshi-Medium",textTransform:"capitalize"}}
                    style={{
                      color: "#6B7280",
                      fontSize: "14px",
                      fontFamily: "Satoshi-Medium",
                      textTransform: "capitalize",
                      paddingInline: "0px",
                      minWidth: "auto",
                      width: "auto",
                      minHeight: "auto",
                    }}
                  />
                  <Tab
                    icon={<ListOutlinedIcon />}
                    iconPosition="start"
                    label="Timeline"
                    value="5"
                    // style={{color:"#6B7280",fontSize:"14px",fontFamily:"Satoshi-Medium",textTransform:"capitalize"}}
                    style={{
                      color: "#6B7280",
                      fontSize: "14px",
                      fontFamily: "Satoshi-Medium",
                      textTransform: "capitalize",
                      paddingInline: "0px",
                      minWidth: "auto",
                      width: "auto",
                      minHeight: "auto",
                    }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ padding: "0px", paddingTop: "24px" }}>
                <Box className="details">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h5"
                        style={{
                          color: "#40444D",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          marginBottom: "4px",
                        }}
                      >
                        Gender
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#1F1F1F",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Medium",
                        }}
                      >
                        {getData?.gender}
                        <img
                          src={femaleIcon}
                          style={{
                            display: "inline-block",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h5"
                        style={{
                          color: "#40444D",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          marginBottom: "4px",
                        }}
                      >
                        Birth Date
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#1F1F1F",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Medium",
                        }}
                      >
                        {formatDate(getData?.birth_date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h5"
                        style={{
                          color: "#40444D",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          marginBottom: "4px",
                        }}
                      >
                        Customer Since
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#1F1F1F",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Medium",
                        }}
                      >
                        {formatDate(getData?.customersince_date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h5"
                        style={{
                          color: "#40444D",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          marginBottom: "4px",
                        }}
                      >
                        Total Number of Visits
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#1F1F1F",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Medium",
                        }}
                      >
                        {getData?.number_of_visits}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h5"
                        style={{
                          color: "#40444D",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          marginBottom: "4px",
                        }}
                      >
                        No Shows
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#1F1F1F",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Medium",
                        }}
                      >
                        1
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h5"
                        style={{
                          color: "#40444D",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          marginBottom: "4px",
                        }}
                      >
                        Cancellations
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#1F1F1F",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Medium",
                        }}
                      >
                        0
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h5"
                        style={{
                          color: "#40444D",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          marginBottom: "4px",
                        }}
                      >
                        Deposit Balance
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#1F1F1F",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Medium",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={dollorIcon}
                          style={{
                            width: "16PX",
                            display: "inlineBlock",
                            marginRight: "4px",
                          }}
                        />
                        {getData?.deposit}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h5"
                        style={{
                          color: "#40444D",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          marginBottom: "4px",
                        }}
                      >
                        Last Skin Test Date
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#1F1F1F",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Medium",
                        }}
                      >
                        {formatDate(getData?.skin_test_date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h5"
                        style={{
                          color: "#40444D",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          marginBottom: "4px",
                        }}
                      >
                        Reminders
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#1F1F1F",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Medium",
                        }}
                      >
                        {getData?.reminder_type}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel
                value="2"
                style={{ padding: "0px", paddingTop: "24px" }}
              >
                {customerAppointments?.map((item, index) => (
                <Box
                  className="customerNote appointmnet"
                  style={{
                    position: "relative",
                    background: "#ffffff",
                    padding: "16px",
                    borderRadius: "12px",
                    position: "relative",
                    marginBottom: "16px",
                    border: "1px solid #E1E3E6",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      position: "absolute",
                      right: "20px",
                      top: "20px",
                    }}
                    className="rebook"
                  >
                    <Button className="rebk">
                      <RefreshIcon />
                      Rebook
                    </Button>
                    <Button
                      className="move dotPopup rebookpop "
                      onClick={() => toggleAppointmentOption()}
                    >
                      <MoreVertIcon />
                      {showAppointment && (
                        <Box
                          className="btnrebook "
                          sx={{
                            mt: 2,
                            padding: "6px 10px",
                            borderRadius: "8px",
                            color: "C81E1E",
                            backgroundColor: "#ffffff",
                            top: "28px",
                            position: "absolute",
                            fontSize: "14px",
                            right: "0px",
                            textTransform: "capitalize",
                            fontFamily: "Plus Jakarta Sans!important",
                            minWidth: "84px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="error"
                            className="deletpopup "
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "6px",
                            }}
                          >
                            <img src={deleticon} /> Delete
                          </Typography>
                        </Box>
                      )}
                    </Button>
                  </Box>
                  <Typography
                    variant="h4"
                    className="biab"
                    style={{
                      color: "#15171A",
                      fontSize: "16px",
                      fontFamily: "Satoshi-Regular",
                      marginBottom: "0px!impportant",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span className="yellowdot">
                      <img
                        src={oragdotIcon}
                        style={{
                          display: "inline-block",
                          width: "8px",
                          marginRight: "8px",
                        }}
                      />
                    </span>
                   {item?.ServiceName}
                    <Box className="statusselect">
                      <select>
                        <option value={item?.Status}>{item?.Status}</option>
                        {/* <option>Not Confirmed</option>
                        <option>Show</option>
                        <option>Canceled</option> */}
                      </select>
                    </Box>
                  </Typography>
                  <Box style={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#898E99",
                        fontSize: "14px",
                        fontFamily: "Satoshi-Regular",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* 10 May, 2024, 10AM-11:30AM */}
                      {item?.AppointmentDate}
                      <Typography
                        style={{
                          color: "#898E99",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          margin: "0px 8px",
                        }}
                      >
                        <Typography variant="span">.</Typography> {item?.SpecialistName}{" "}
                        <Typography variant="span">.</Typography>
                      </Typography>
                      <Typography
                        style={{
                          color: "#898E99",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          display: "flex",
                        }}
                        className="tag"
                      >
                        <SellOutlinedIcon /> $ {item?.Price}
                      </Typography>
                    </Typography>
                  </Box>
                  {item?.AppointmentNotes?.map((item, index) => (
                    
                
                  <Box
                    className="bottom_note"
                    style={{
                      display: "flex",
                      gap: "6px",
                      padding: "8px",
                      background: "#F0F1F2",
                      borderRadius: "12px",
                      marginTop: "12px",
                    }}
                  >
                    <Box className="tagline">
                      <DescriptionOutlinedIcon />
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#2B2E33",
                          fontSize: "12px",
                          fontFamily: "Satoshi-Medium",
                        }}
                      >
                        {item?.note}
                      </Typography>
                      <Typography
                        variant="p"
                        style={{
                          color: "#6B7280",
                          fontSize: "12px",
                          fontFamily: "Satoshi-Regular",
                        }}
                      >
                        by Manager name, {item?.date}, {item?.time}
                      </Typography>
                    </Box>
                  </Box>  ))}
                </Box>))}
                <Box
                  className="customerNote appointmnet"
                  style={{
                    position: "relative",
                    background: "#ffffff",
                    padding: "16px",
                    borderRadius: "12px",
                    position: "relative",
                    marginBottom: "16px",
                    border: "1px solid #E1E3E6",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      position: "absolute",
                      right: "20px",
                      top: "20px",
                    }}
                    className="rebook"
                  >
                    <Button className="rebk">
                      <RefreshIcon />
                      Rebook
                    </Button>
                    <Button className="move">
                      <MoreVertIcon />
                    </Button>
                  </Box>
                  <Typography
                    variant="h4"
                    className="biab"
                    style={{
                      color: "#15171A",
                      fontSize: "16px",
                      fontFamily: "Satoshi-Regular",
                      marginBottom: "0px!impportant",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span className="yellowdot">
                      <img
                        src={oragdotIcon}
                        style={{
                          display: "inline-block",
                          width: "8px",
                          marginRight: "8px",
                        }}
                      />
                    </span>
                    BIAB with shellac & removal
                    <Box className="statusselect">
                      <select>
                        <option>Confirmed</option>
                        <option>Not Confirmed</option>
                        <option>Show</option>
                        <option>Canceled</option>
                      </select>
                    </Box>
                  </Typography>
                  <Box style={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#898E99",
                        fontSize: "14px",
                        fontFamily: "Satoshi-Regular",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      10 May, 2024, 10AM-11:30AM
                      <Typography
                        style={{
                          color: "#898E99",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          margin: "0px 8px",
                        }}
                      >
                        <Typography variant="span">.</Typography>
                        Abril Lewis <Typography variant="span">.</Typography>
                      </Typography>
                      <Typography
                        style={{
                          color: "#898E99",
                          fontSize: "14px",
                          fontFamily: "Satoshi-Regular",
                          display: "flex",
                        }}
                        className="tag"
                      >
                        <SellOutlinedIcon /> $ 150
                      </Typography>
                    </Typography>
                  </Box>
                  <Box
                    className="bottom_note"
                    style={{
                      display: "flex",
                      gap: "6px",
                      padding: "8px",
                      borderRadius: "12px",
                      marginTop: "0px",
                      fontFamily: "Satoshi-Regular",
                      paddingLeft: "0px",
                    }}
                  >
                    <Typography>
                      <b>Payment Details:</b>
                      <span style={{ color: "#40444D", fontSize: "14px" }}>
                        {" "}
                        Card Total Paid: $80 (Incl. Deposit -$50.00, Discount
                        -$20.00)
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel
                value="3"
                style={{ padding: "0px", paddingTop: "24px" }}
              >
                {customerNotes?.map((item, index) => (
                <Box
                  key={index}
                  className="customerNote"
                  style={{
                    background: "#F0F1F2",
                    padding: "16px",
                    borderRadius: "12px",
                    position: "relative",
                    marginBottom: "16px",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: "#898E99",
                      fontSize: "12px",
                      fontFamily: "Satoshi-Medium",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      marginBottom: "8px",
                      lineHeight: " 1.5",
                    }}
                  >
                    <img
                      src={allergyIcon}
                      style={{
                        display: "inline-block",
                        width: "16px",
                        height: "16px",
                      }}
                    />
                    {item.type}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#2B2E33",
                      fontSize: "14px",
                      fontFamily: "Satoshi-Regular",
                      marginBottom: "8px",
                      lineHeight: " 1.5",
                    }}
                  >
                    {item?.description}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#898E99",
                      fontSize: "12px",
                      fontFamily: "Satoshi-Regular",
                      marginBottom: "8px",
                      lineHeight: " 1.5",
                    }}
                  >
                    by You, {item?.createdAt}
                  </Typography>
                  <Typography
                    className="sideDot"
                    style={{
                      position: "absolute",
                      right: "0px",
                      top: "16px",
                    }}
                  >
                    <Button onClick={()=>toggleMoreOption(index)}>
                      <img
                        src={dotButtonIcon}
                        style={{ width: "32px", height: "32px" }}
                      />
                    </Button>
                    {showMore===index && (
                      <Box
                        className="editbt"
                        sx={{
                          mt: 2,
                          borderRadius: "8px",
                          backgroundColor: "#ffffff",
                          minWidth: "78px",
                          fontFamily: "Plus Jakarta Sans !important",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          position: "absolute",
                          top: "28px",
                          right: "0px",
                          color: "#d32f2",
                          padding: "6px 10px",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          boxShadow: "1px 1px 3px 1px #d0d0d08a",
                          flexDirection: "column",
                          zIndex: "1",
                        }}
                      >
                        <Typography
                          variant="body1"
                          color="error"
                          style={{
                            marginBottom: "6px",
                            color: "rgb(64, 68, 77)",
                          }}
                        >
                          <EditIcon />
                          Edit
                        </Typography>
                        <Typography
                          variant="body1"
                          color="error"
                          className="deletimgg"
                          onClick={()=>handleDelete(item?.customerId)}
                        >
                          <img src={deleticon} />
                          Delete
                        </Typography>
                      </Box>
                    )}
                  </Typography>
                </Box>))}
                {/* <Box
                  className="customerNote"
                  style={{
                    background: "#F0F1F2",
                    padding: "16px",
                    borderRadius: "12px",
                    position: "relative",
                    marginBottom: "16px",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: "#898E99",
                      fontSize: "12px",
                      fontFamily: "Satoshi-Medium",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      marginBottom: "8px",
                      lineHeight: " 1.5",
                    }}
                  >
                    <img
                      src={pantoneIcon}
                      style={{
                        display: "inline-Block",
                        width: "16px",
                        height: "16px",
                      }}
                    />
                    FORMULA
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#2B2E33",
                      fontSize: "14px",
                      fontFamily: "Satoshi-Regular",
                      marginBottom: "8px",
                      lineHeight: " 1.5",
                    }}
                  >
                    Sensitive to strong scents; use unscented products where
                    possible.
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#898E99",
                      fontSize: "12px",
                      fontFamily: "Satoshi-Regular",
                      marginBottom: "8px",
                      lineHeight: " 1.5",
                    }}
                  >
                    by Irene Miller, Apr 19, 2024, 3PM
                  </Typography>
                  <Typography
                    className="sideDot"
                    style={{ position: "absolute", right: "0px", top: "16px" }}
                  >
                    <Button>
                      <img
                        src={dotButtonIcon}
                        style={{ width: "32px", height: "32px" }}
                      />
                    </Button>
                  </Typography>
                </Box> */}
                <Button
                  className="addNote"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                    gap: "8px",
                  }}
                  onClick={handleOpenModal}
                >
                  <AddIcon />
                  Add Note
                </Button>
              </TabPanel>
              <TabPanel
                value="4"
                style={{ padding: "0px", paddingTop: "24px" }}
              >
                 {customerAppointments?.map((item, index) => (
                <Box
                  className="customerNote fiels"
                  style={{
                    background: "#F0F1F2",
                    padding: "16px",
                    borderRadius: "12px",
                    position: "relative",
                    marginBottom: "16px",
                  }}
                >
                    {item?.Attachments?.map((item, index) => (
                      <>
                       <Typography
                       variant="h6"
                       style={{
                         color: "#2B2E33",
                         fontSize: "14px",
                         fontFamily: "Satoshi-Regular",
                         display: "flex",
                         alignItems: "center",
                         gap: "4px",
                         marginBottom: "8px",
                         lineHeight: " 1.5",
                       }}
                     >
                       <img
                         src={fileIcon}
                         style={{
                           display: "inline-block",
                           width: "16px",
                           height: "16px",
                         }}
                       />
                       Skin Test 13/08/2023.pdf {item}
                     </Typography>
                       <Typography
                       variant="h6"
                       style={{
                         color: "#898E99",
                         fontSize: "12px",
                         fontFamily: "Satoshi-Regular",
                         marginBottom: "8px",
                         lineHeight: " 1.5",
                       }}
                     >
                       by You, Apr 19, 2024, 3PM
                     </Typography>
                     </>
                 ))}
               
                
                  <Typography
                    className="sideDot"
                    style={{ position: "absolute", right: "0px", top: "16px" }}
                  >
                    <Button></Button>
                    <Button>
                      <img
                        src={downloadIcon}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "14px",
                        }}
                      />
                      <img
                        src={dotButtonIcon}
                        style={{ width: "32px", height: "32px" }}
                      />
                    </Button>
                  </Typography>
                </Box> ))}
                <Box className="attchdiv">
                  <Button className="attchfile" component="label">
                    <AttachFileIcon sx={{ marginRight: "8px" }} />
                    Attach File
                    <input type="file" hidden multiple />
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel
                value="5"
                style={{ padding: "0px", paddingTop: "24px" }}
              >
                <Box>
                  <CustomTimeline customerTimeline={customerTimeline}/>
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
        <>
          <AddCustomerNotes open={isModalOpen} handleClose={handleCloseModal} customerID={appointments[0]?.CustomerId} />
          <LoyaltyPointModal
            open={openLoyaltyModal}
            handleClose={handleCloseLoyaltyModal}
          />
        </>
      </Box>

      {openCreatCustomerModal && (
        <>
          <AddCustomer
            addCustomerToogle={addCustomerToggle}
            setAddCustomerToggle={setAddCustomerToggle}
          />
        </>
      )}
    </LocalizationProvider>
  );
};

export default AddCustomerDetail;
