import React from "react";
import Drawer from "@mui/material/Drawer";
import AddToWaitListModalForm from "./AddToWaitListModalForm";
const AddToWishListModal = ({ addToWaitListModal, setAddToWaitListModal }) => {
  return (
    <>
      <Drawer
        anchor={"right"}
        open={addToWaitListModal}
        sx={{
          "& .MuiDrawer-paper": {
            maxWidth: "900px !important",
          },
        }}
      >
        <AddToWaitListModalForm
          addToWaitListModal={addToWaitListModal}
          setAddToWaitListModal={setAddToWaitListModal}
        />
      </Drawer>
    </>
  );
};

export default AddToWishListModal;
