import React, { useEffect, useRef, useState } from "react";
import Drawer from '@mui/material/Drawer';
import CreateAppointment from "./createAppointment";
import BookAppointmentDetail from "./BookAppointmentDetail";

export default function BookAppointment({
    bookAppointmentToggle,
    setBookAppointmentToggle,
    selectedEventId,
    customerId
}) {
    return (
        <>
            <Drawer
                anchor={"right"}
                open={bookAppointmentToggle}
                sx={{
                    "& .MuiDrawer-paper":{
                        maxWidth:"900px !important"
                    }
                }}
            >
                <BookAppointmentDetail 
                    bookAppointmentToggle={bookAppointmentToggle}
                    setBookAppointmentToggle={setBookAppointmentToggle} 
                    selectedEventId={selectedEventId}
                    customerId={customerId}/>
            </Drawer>

        </>
    )
}