import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createCustomer = createAsyncThunk(
  "api/createCustomer",
  async ({formData, toast}, { rejectWithValue }) => {
    try {
   
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/`,
        formData,
        {
          headers: {
            // Accept: "application/json",
            "Content-Type": "multipart/form-data"
          },
        }
      );
     toast.success("Customer Created Successfully");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const createCustomerSlice = createSlice({
  name: "createCustomer",
  initialState: {
    all: {
      data: [],
      loading: false,
      error: null,
    },
    add: {
      data: {},
      loading: false,
      error: null,
    },

  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCustomer.pending, (state) => {
        if (!state.add) state.add = {};
        state.add.loading = true;
        state.add.error = null;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.add.loading = false;
        state.add.data = action.payload;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.add.loading = false;
        state.add.error = action.payload;
      });

  },
});

export const createCustomerReducer = createCustomerSlice.reducer;
