import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const drawerExpand = createSlice({
  name: "drawerExpand",
  initialState: {
    all: {
      data: { drawerStatus: false },
      loading: false,
      error: null,
    }
  },
  reducers: {
    toggleDrawerStatus(state) {
      state.all.data.drawerStatus = !state.all.data.drawerStatus;
    },
    setDrawerStatus(state, action) {
      state.all.data.drawerStatus = action.payload;
    },
  }
});

export const { toggleDrawerStatus, setDrawerStatus } = drawerExpand.actions;
export const drawerExpandReducer = drawerExpand.reducer;